import { ActionContext, Module } from 'vuex';
import * as Mutations from './mutations';
import MeetingRoomsHelper from '@/_modules/meeting-rooms/helpers/meeting-rooms.helper';
import { MeetingRoomType } from '@/_modules/meeting-rooms/types/meeting-room-type.enum';
import { TMeetingRoomsStoreState } from '@/_modules/meeting-rooms/types/meeting-rooms-store-state.type';
import { TAppStoreState } from '@/_types/store/app-store-state.type';
import { TMeetingRoomState } from '@/_modules/meeting-rooms/types/meeting-room-state.type';
import { TMeetingRoomConfig } from '@/_modules/meeting-rooms/types/meeting-room-config.type';

const meetingRoomsStore: Module<TMeetingRoomsStoreState, TAppStoreState> = {
  namespaced: true,
  state: {
    meetingRooms: [],
  },
  getters: {
    getMeetingRoomById: (state: TMeetingRoomsStoreState): (id: number) => TMeetingRoomState => {
      return (meetingRoomId: number): TMeetingRoomState => {
        return state.meetingRooms.find((meetingRoom: TMeetingRoomState) => meetingRoom.id === meetingRoomId);
      };
    },
    meetings: (state: TMeetingRoomsStoreState): TMeetingRoomState[] => {
      return state.meetingRooms.filter((meetingRoom: TMeetingRoomState) => meetingRoom.config.type === MeetingRoomType.MEETING);
    },
    broadcasts: (state: TMeetingRoomsStoreState): TMeetingRoomState[] => {
      return state.meetingRooms.filter((meetingRoom: TMeetingRoomState) => meetingRoom.config.type === MeetingRoomType.BROADCAST);
    },
  },
  actions: {
    join({ commit }: ActionContext<TMeetingRoomsStoreState, TAppStoreState>, meetingRoomConfig: TMeetingRoomConfig): void {
      commit(Mutations.MEETING_ROOM_ADD, {
        id: MeetingRoomsHelper.getMeetingRoomId(meetingRoomConfig),
        config: meetingRoomConfig,
        isMinimized: false,
        isMaximized: false,
      });
    },

    leave({ commit }: ActionContext<TMeetingRoomsStoreState, TAppStoreState>, meetingRoomId: number): void {
      commit(Mutations.MEETING_ROOM_REMOVE, meetingRoomId);
    },

    minimize({ commit }: ActionContext<TMeetingRoomsStoreState, TAppStoreState>, meetingRoomId: number): void {
      commit(Mutations.MEETING_ROOM_SET_IS_MINIMIZED, { id: meetingRoomId, isMinimized: true });
    },

    unMinimize({ commit }: ActionContext<TMeetingRoomsStoreState, TAppStoreState>, meetingRoomId: number): void {
      commit(Mutations.MEETING_ROOM_SET_IS_MINIMIZED, { id: meetingRoomId, isMinimized: false });
    },

    maximize({ commit }: ActionContext<TMeetingRoomsStoreState, TAppStoreState>, meetingRoomId: number): void {
      commit(Mutations.MEETING_ROOM_SET_IS_MAXIMIZED, { id: meetingRoomId, isMaximized: true });
    },

    unMaximize({ commit }: ActionContext<TMeetingRoomsStoreState, TAppStoreState>, meetingRoomId: number): void {
      commit(Mutations.MEETING_ROOM_SET_IS_MAXIMIZED, { id: meetingRoomId, isMaximized: false });
    },

  },
  mutations: {
    [Mutations.MEETING_ROOM_ADD]: (state: TMeetingRoomsStoreState, newMeetingRoom: TMeetingRoomState): void => {
      const { meetingRooms } = state;
      let isMeetingRoomAlreadyExists = false;
      meetingRooms.forEach((meetingRoom: TMeetingRoomState) => {
        if (meetingRoom.id === newMeetingRoom.id) {
          meetingRoom.isMinimized = false;
          isMeetingRoomAlreadyExists = true;
        } else {
          meetingRoom.isMinimized = true;
        }
      });
      if (!isMeetingRoomAlreadyExists) {
        newMeetingRoom.isMinimized = false;
        meetingRooms.push(newMeetingRoom);
      }
    },

    [Mutations.MEETING_ROOM_REMOVE]: (state: TMeetingRoomsStoreState, meetingRoomId: number): void => {
      const { meetingRooms } = state;
      for (let index = 0; index < meetingRooms.length; index++) {
        if (meetingRooms[index].id === meetingRoomId) {
          meetingRooms.splice(index, 1);
          break;
        }
      }
    },

    [Mutations.MEETING_ROOM_SET_IS_MINIMIZED]: (state: TMeetingRoomsStoreState, { id, isMinimized }: { id: number; isMinimized: boolean }): void => {
      const { meetingRooms } = state;
      for (let index = 0; index < meetingRooms.length; index++) {
        if (meetingRooms[index].id === id) {
          meetingRooms[index].isMinimized = isMinimized;
          break;
        }
      }
    },

    [Mutations.MEETING_ROOM_SET_IS_MAXIMIZED]: (state: TMeetingRoomsStoreState, { id, isMaximized }: { id: number; isMaximized: boolean }): void => {
      const { meetingRooms } = state;
      for (let index = 0; index < meetingRooms.length; index++) {
        if (meetingRooms[index].id === id) {
          meetingRooms[index].isMaximized = isMaximized;
          break;
        }
      }
    },

  },
};

export default meetingRoomsStore;
