import api from '../../api';
import {TMeetingsList} from "@/_types/store/meetings-list.type";

export default {
  strict: true,
  namespaced: true,
  state: {
    meetingsError: '',
    meetingsPage: {
    },
    userSchedule: {
    },
    userScheduleForNotificationsPopup: {
    },
  },
  getters: {},
  mutations: {
    setMeetingsError(state, data) {
      state.meetingsError = data;
    },
    setMeetingsPage(state, data) {
      state.meetingsPage = data;
    },
    setUserSchedule(state, data) {
      state.userSchedule = data;
    },
    setUserScheduleForNotificationsPopup(state, data) {
      state.userScheduleForNotificationsPopup = data;
    },
  },
  actions: {
    /* Full meetings list for display on
     * /calendar/
     */
    getMeetingsList: async function ({commit}, payload) {
      await api.meetings.getMeetingsList(payload)
        .then((resolve) => {
          commit('setMeetingsPage', resolve.data);
        }).catch((e) => {
          commit('setMeetingsError', e.data);
        });
    },
    /* Simpler meetings list for display on
     * contact card Calendar tab
     */
    getUserSchedule: async function ({commit}, payload) {
      await api.meetings.getMeetingsList(payload)
        .then((resolve) => {
          commit('setUserSchedule', resolve.data);
        }).catch((e) => {
          commit('setMeetingsError', e.data);
        });
    },
    /* Simpler, SEPARATE meetings list for display in
     * notifications-popup
     */
    getUserScheduleForNotificationsPopup: async function ({commit}, payload) {
      await api.meetings.getMeetingsList(payload)
        .then((resolve) => {
          commit('setUserScheduleForNotificationsPopup', resolve.data);
        }).catch((e) => {
          commit('setMeetingsError', e.data);
        });
    },
    /* Creates a meeting which has yet to be
     * confirmed or rejected by an event owner
     */
    requestMeeting: async function ({commit}, payload) {
      try{
        return await api.meetings.requestMeeting(payload);
      } catch(e){
        commit('setMeetingsError', e.data);
        return e.data; // error is instanceof APIError, must have the .data field
      }
    },
    /* Creates a meeting which has yet to be
     * confirmed or rejected by an event owner
     */
    confirmMeeting: async function ({commit}, payload) {
      try {
        return await api.meetings.confirmMeeting(payload);
      } catch(e){
        commit('setMeetingsError', e.data);
        return e.data; // error is instanceof APIError, must have the .data field
      }
    },
    /* Cancels (rejects) a meeting request
     */
    cancelMeeting: async function ({commit}, payload) {
      try {
        return await api.meetings.cancelMeeting(payload);
      } catch(e){
        commit('setMeetingsError', e.data);
        return e.data; // error is instanceof APIError, must have the .data field
      }
    },

  }
}
