import Vuex from 'vuex';
import { TAppStoreState } from '@/_types/store/app-store-state.type';

import authStore from '@/_store/auth.store';
import _userStore from '@/_store/user.store';
import meetingRoomsStore from '@/_modules/meeting-rooms/store/meeting-rooms.store';
import _eventStore from '@/_modules/events/store/event.store';
import promoPageStore from '@/_modules/promo/store/promo-page.store';
import statisticStore from '@/_modules/statistics/store/statistics.store';
import _badgeNotificationsStore from '@/_modules/notifications-popup/store/notifications-popup.store';
import _messageStore from '@/_modules/notifications-popup/store/messages.store';
import editFormStore from '@/_store/edit-form.store';
import cabinetLobbyStore from '@/_modules/promo-cabinet/store/cabinet-lobby.store';
import promoProgramStore from '@/_modules/promo-program/store/promo-program.store';
import promoContactsStore from '@/_modules/promo/store/promo-contacts.store';
import chatStore from '@/_modules/chat/store/chat.store';
import eventChatStore from '@/_modules/event-chat/store/event-chat.store';
import sideBarRightStore from '@/_modules/promo/store/side-bar-right.store.ts';

/* old */

// TODO: refactoring
// @ts-ignore
import userStore from '@/store/mudules/userStore.js';
// @ts-ignore
import languageStore from '@/store/mudules/languageStore.js';
// @ts-ignore
import eventStore from '@/store/mudules/eventStore.js';
// @ts-ignore
import ticketsStore from '@/store/mudules/ticketsStore.js';
// @ts-ignore
import uploadImageStore from '@/store/mudules/uploadImageStore.js';
// @ts-ignore
import locationStore from '@/store/mudules/locationStore.js';
// @ts-ignore
import contactStore from '@/store/mudules/contactStore.js';
// @ts-ignore
import noteStore from '@/store/mudules/noteStore.js';
// @ts-ignore
import promoStore from '@/store/mudules/promoStore.js';
// @ts-ignore
// import messageStore from '@/store/mudules/messageStore.js';
// @ts-ignore
import meetingsStore from '@/store/mudules/meetingsStore.js';
// @ts-ignore
import seatplanStore from '@/store/mudules/seatplanStore.js';
// @ts-ignore
import uploadProductsStore from '@/store/mudules/uploadProductsStore.js';
// @ts-ignore
import uploadPromopageFilesStore from '@/store/mudules/uploadPromopageFilesStore.js';
// @ts-ignore
// import badgeNotificationsStore from '@/store/mudules/badgeNotificationsStore.js';

const store = new Vuex.Store<TAppStoreState>({
  strict: true,
  modules: {
    authStore,
    _userStore,
    meetingRoomsStore,
    _eventStore,
    promoPageStore,
    statisticStore,
    chatStore,
    eventChatStore,
    editFormStore,
    cabinetLobbyStore,
    promoProgramStore,
    promoContactsStore,
    sideBarRightStore,

    /* old stores */
    userStore,
    languageStore,
    eventStore,
    ticketsStore,
    uploadImageStore,
    locationStore,
    contactStore,
    noteStore,
    promoStore,
    // messageStore,
    _messageStore,
    meetingsStore,
    seatplanStore,
    uploadProductsStore,
    uploadPromopageFilesStore,
    // badgeNotificationsStore,
    _badgeNotificationsStore,
  },
});

export default store;
