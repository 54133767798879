


import Component from 'vue-class-component';
import { Vue } from 'vue-property-decorator';
import AuthContent from '@/_components/auth-content/auth-content.vue';

@Component({
  name: 'auth-popup',
  components: {
    AuthContent
  },
})
export default class AuthPopup extends Vue {
}
