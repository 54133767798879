import { ActionContext, Module } from 'vuex';
import AxiosCancellableRequest from '@/_types/api/axios-cancellable-request.class';
import { TAppStoreState } from '@/_types/store/app-store-state.type';
import { TPromoContactsStoreState } from '@/_modules/promo/types/promo-contacts-store-state.type';
import { TContact } from '@/_types/contact.type';
import contactsApi, { TGetAllEventContactsParams } from '@/_api/contacts/contacts.api';
import ContactHelper from '@/_helpers/contact.helper';

const loadAllRequest = new AxiosCancellableRequest<TGetAllEventContactsParams, TContact[]>(contactsApi.getAllEventContacts.bind(contactsApi));

const promoContactsStore: Module<TPromoContactsStoreState, TAppStoreState> = {
  namespaced: true,
  state: {
    eventId: null,
    contactsById: {},
    contacts: [],
    isLoading: false,
    lastError: null,
  },
  getters: {

    eventId: (state: TPromoContactsStoreState): number => {
      return state.eventId;
    },

    isLoading: (state: TPromoContactsStoreState): boolean => {
      return state.isLoading;
    },

    lastError: (state: TPromoContactsStoreState): Error => {
      return state.lastError;
    },

    contacts: (state: TPromoContactsStoreState): TContact[] => {
      return state.contacts;
    },

    contactById(state: TPromoContactsStoreState, contactId: number): TContact {
      return (state.contactsById && state.contactsById[contactId])
        ? state.contactsById[contactId]
        : null;
    },

  },
  actions: {

    reset: ({ commit }: ActionContext<TPromoContactsStoreState, TAppStoreState>): void => {
      commit('setEventId', null);
    },

    setEventId: async ({ commit, state }: ActionContext<TPromoContactsStoreState, TAppStoreState>, eventId: number): Promise<void> => {
      commit('setEventId', eventId);

      if (!state.eventId) {
        return;
      }

      commit('loadAllRequest');

      let data;
      try {
        data = await loadAllRequest.load({ eventId: state.eventId });
      } catch (error) {
        commit('setLastError', error);
      } finally {
        commit('setContacts', data);
      }
    },

  },
  mutations: {

    setEventId(state: TPromoContactsStoreState, eventId: number): void {
      if (state.eventId === eventId) {
        return;
      }
      state.eventId = eventId;
      state.contactsById = {};
      state.isLoading = false;
      state.lastError = null;

      loadAllRequest.cancel();
    },

    loadAllRequest(state: TPromoContactsStoreState): void {
      state.isLoading = true;
      state.lastError = null;
    },

    setLastError(state: TPromoContactsStoreState, lastError: Error): void {
      state.lastError = lastError;
    },

    setContacts(state: TPromoContactsStoreState, contacts: TContact[]): void {
      state.isLoading = false;

      if (!contacts) {
        return;
      }

      state.contactsById = {};
      state.contacts = [];
      contacts.forEach((contact: TContact): void => {
        contact.fullName = ContactHelper.getFullName(contact);
        state.contactsById[contact.id] = contact;
        state.contacts.push(contact);
      });
    },

  },
};

export default promoContactsStore;
