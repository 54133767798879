export default class UrlHelper {

  public static addMissingUrlSchema(url: string = ''): string {
    if (url && ('' + url).indexOf('://') === -1) {
      return 'http://' + url;
    } else {
      return url;
    }
  }

  /* Check whether the given domain is same as hostname of current window
   * (not accounting for embedded frames)
   * (not accounting for port)
   */
  public static isSameDomain(url: string = ''): boolean {
    return (window as any).location.hostname === url;
  }

  /* Check whether a given URL is external
   */
  public static isUrlExternal(url: string): boolean {
    return /^https?:\/\//.test(url) && !this.isSameDomain(url);
  }

}
