import { ActionContext, Module } from 'vuex';
import { TAppStoreState } from '@/_types/store/app-store-state.type';
import { TEventChatStoreState } from '@/_modules/event-chat/types/event-chat-store-state.type';
import { TChatMessage } from '@/_modules/chat/types/chat-message.type';
import { TChatContact } from '@/_modules/chat/types/chat-contact.type';
import { ChatMessageType } from '@/_modules/chat/types/chat-message-type.enum';
import { TChatTextMessagePayload } from '@/_modules/chat/types/chat-text-message-payload.type';
import { TChatCustomMessagePayload } from '@/_modules/chat/types/chat-custom-message-payload.type';

const filterMessages = (messages: TChatMessage[]): TChatMessage[] => messages.filter((message: TChatMessage) => {
  return message && (
    (message.type === ChatMessageType.TEXT && (message.payload as TChatTextMessagePayload).text.indexOf('[System:ChatContactUpdated]') === -1)
    || (message.type === ChatMessageType.CUSTOM && (message.payload as TChatCustomMessagePayload).description === 'kicked-out')
  );
});

const eventChatStore: Module<TEventChatStoreState, TAppStoreState> = {
  namespaced: true,
  state: {
    isDialogVisible: false,
    isContactsListVisible: false,
    messages: [],
    contactsListEntity: {
      isLoading: false,
      data: [],
      error: null,
    },
    isLoading: false,
    connectError: null,
    chatContact: null,
  },
  getters: {

    isLoading: (state: TEventChatStoreState): boolean => {
      return state.isLoading;
    },
    connectError: (state: TEventChatStoreState): Error => {
      return state.connectError;
    },
    isDialogVisible: (state: TEventChatStoreState): boolean => {
      return state.isDialogVisible;
    },
    isContactsListVisible: (state: TEventChatStoreState): boolean => {
      return state.isContactsListVisible;
    },
    countContacts: (state: TEventChatStoreState): number => {
      return state.contactsListEntity.data.length;
    },
    messages: (state: TEventChatStoreState): TChatMessage[] => {
      return state.messages;
    },
    contactsList: (state: TEventChatStoreState): TChatContact[] => {
      return state.contactsListEntity.data;
    },
    contactsListError: (state: TEventChatStoreState): Error => {
      return state.contactsListEntity.error;
    },
    isContactsListLoading: (state: TEventChatStoreState): boolean => {
      return state.contactsListEntity.isLoading;
    },
    chatContact: (state: TEventChatStoreState): TChatContact => {
      return state.chatContact;
    },

  },
  actions: {

    reset: ({ commit }: ActionContext<TEventChatStoreState, TAppStoreState>): void => {
      commit('reset');
    },

    setIsLoading: ({ commit }: ActionContext<TEventChatStoreState, TAppStoreState>, isLoading: boolean): void => {
      commit('setIsLoading', isLoading);
    },

    setIsDialogVisible: ({ commit }: ActionContext<TEventChatStoreState, TAppStoreState>, isDialogVisible: boolean): void => {
      commit('setIsDialogVisible', isDialogVisible);
    },

    toggleIsDialogVisible: ({ commit, state }: ActionContext<TEventChatStoreState, TAppStoreState>): void => {
      commit('setIsDialogVisible', !state.isDialogVisible);
    },

    setIsContactsListVisible: ({ commit }: ActionContext<TEventChatStoreState, TAppStoreState>, isContactsListVisible: boolean): void => {
      commit('setIsContactsListVisible', isContactsListVisible);
    },

    setMessages: ({ commit }: ActionContext<TEventChatStoreState, TAppStoreState>, messages: TChatMessage[]): void => {
      commit('setMessages', messages);
    },

    appendMessages: ({ commit }: ActionContext<TEventChatStoreState, TAppStoreState>, messages: TChatMessage[]): void => {
      commit('appendMessages', messages);
    },

    setChatContact: ({ commit }: ActionContext<TEventChatStoreState, TAppStoreState>, chatContact: TChatContact): void => {
      commit('setChatContact', chatContact);
    },

    setConnectError: ({ commit }: ActionContext<TEventChatStoreState, TAppStoreState>, error: Error): void => {
      commit('setConnectError', error);
    },

    setContactsList: ({ commit }: ActionContext<TEventChatStoreState, TAppStoreState>, contactsList: TChatContact[]): void => {
      commit('setContactsList', contactsList);
    },

    setIsContactsListLoading: ({ commit }: ActionContext<TEventChatStoreState, TAppStoreState>, isContactsListLoading: boolean): void => {
      commit('setIsContactsListLoading', isContactsListLoading);
    },

    setContactsListError: ({ commit }: ActionContext<TEventChatStoreState, TAppStoreState>, error: Error): void => {
      commit('setContactsListError', error);
    },

    kickedOut: ({ commit }: ActionContext<TEventChatStoreState, TAppStoreState>, reason: string): void => {
      commit('kickedOut', reason);
    },

  },
  mutations: {

    reset(state: TEventChatStoreState): void {
      state.isDialogVisible = false;
      state.isContactsListVisible = false;
      state.messages = [];
      state.contactsListEntity.isLoading = false;
      state.contactsListEntity.data = [];
      state.contactsListEntity.error = null;
      state.isLoading = false;
    },

    setIsLoading(state: TEventChatStoreState, isLoading: boolean): void {
      state.isLoading = isLoading;
    },

    setIsDialogVisible(state: TEventChatStoreState, isDialogVisible: boolean): void {
      state.isDialogVisible = isDialogVisible;
    },

    setIsContactsListVisible(state: TEventChatStoreState, isContactsListVisible: boolean): void {
      state.isContactsListVisible = isContactsListVisible;
    },

    setMessages(state: TEventChatStoreState, messages: TChatMessage[]): void {
      if (!messages.length) {
        return;
      }
      state.messages = [ ...filterMessages(messages) ];
    },

    appendMessages(state: TEventChatStoreState, messages: TChatMessage[]): void {
      if (!messages.length) {
        return;
      }
      state.messages = state.messages.concat(filterMessages(messages));
    },

    setContactsList(state: TEventChatStoreState, contactsList: TChatContact[]): void {
      state.contactsListEntity.data = contactsList;
    },

    setIsContactsListLoading(state: TEventChatStoreState, isContactsListLoading: boolean): void {
      state.contactsListEntity.isLoading = isContactsListLoading;
    },

    setContactsListError(state: TEventChatStoreState, error: Error): void {
      state.contactsListEntity.error = error;
    },

    setConnectError(state: TEventChatStoreState, error: Error): void {
      state.connectError = error;
    },

    setChatContact(state: TEventChatStoreState, chatContact: TChatContact): void {
      state.chatContact = chatContact;
      for (let index = 0; index < state.contactsListEntity.data.length; index++) {
        if (state.contactsListEntity.data[index].id === chatContact.id) {
          state.contactsListEntity.data[index] = chatContact;
          state.contactsListEntity.data = [ ...state.contactsListEntity.data ];
          break;
        }
      }
    },

  }
};

export default eventChatStore;
