import RouterHelper from '@/_helpers/router.helper';

const statisticsRoutes = [ { // this should not be array
  path: 'result', // and this should be 'result',
  name: 'result',
  component: (): Promise<object> => import('@/_modules/statistics/components/statistics/statistics.vue'),
  beforeEnter: RouterHelper.authorized,
  redirect: {
    name: 'result-personal',
  },
  children: [
    {
      path: 'personal',
      name: 'result-personal',
      component: (): Promise<object> => import('@/_modules/statistics/components/personal/personal.vue'),
      beforeEnter: RouterHelper.authorized,
    },
    {
      path: 'company',
      name: 'result-company',
      component: (): Promise<object> => import('@/_modules/statistics/components/company/company.vue'),
      beforeEnter: RouterHelper.authorized,
    },
    {
      path: 'event',
      name: 'result-event',
      component: (): Promise<object> => import('@/_modules/statistics/components/event-stat/event-stat.vue'),
      beforeEnter: RouterHelper.authorized,
    },
  ],
} ];

export default statisticsRoutes;
