import { ActionContext, Module } from 'vuex';
import { TAppStoreState } from '@/_types/store/app-store-state.type';
import { TChatStoreState } from '@/_modules/chat/types/chat-store-state.type';
import { ChatConnectStatus } from '@/_modules/chat/types/chat-connect-status.enum';
import { TChatMessage } from '@/_modules/chat/types/chat-message.type';
import { ChatMessageType } from '@/_modules/chat/types/chat-message-type.enum';
import { TChatTextMessagePayload } from '@/_modules/chat/types/chat-text-message-payload.type';
import { TChatCustomMessagePayload } from '@/_modules/chat/types/chat-custom-message-payload.type';
import { TChatGroup } from '@/_modules/chat/types/chat-group.type';
import { TChatGroupState } from '@/_modules/chat/types/chat-group-state.type';

// TODO: get rid of this
const filterMessages = (messages: TChatMessage[]): TChatMessage[] => messages.filter((message: TChatMessage) => {
  return message && (
    (message.type === ChatMessageType.TEXT && (message.payload as TChatTextMessagePayload).text.indexOf('[System:ChatContactUpdated]') === -1)
    || (message.type === ChatMessageType.CUSTOM && (message.payload as TChatCustomMessagePayload).description === 'kicked-out')
  );
});

const chatStore: Module<TChatStoreState, TAppStoreState> = {
  namespaced: true,
  state: {
    isConfigured: false,
    connectStatus: ChatConnectStatus.DISCONNECTED,
    isKickedOut: false,
    kickedOutReason: null,
    lastError: null,
    groups: [],
  },
  getters: {

    isConfigured: (state: TChatStoreState): boolean => {
      return state.isConfigured;
    },
    connectStatus: (state: TChatStoreState): ChatConnectStatus => {
      return state.connectStatus;
    },
    isKickedOut: (state: TChatStoreState): boolean => {
      return state.isKickedOut;
    },
    kickedOutReason: (state: TChatStoreState): string => {
      return state.kickedOutReason;
    },
    lastError: (state: TChatStoreState): Error => {
      return state.lastError;
    },
    getChatGroupState: (state: TChatStoreState) => (groupId: string): TChatGroupState => {
      const existingGroupState = state.groups.find(groupState => groupState.group.id === groupId);
      return existingGroupState || null;
    },

  },
  actions: {

    setIsConfigured: ({ commit }: ActionContext<TChatStoreState, TAppStoreState>, isConfigured: boolean): void => {
      commit('setIsConfigured', isConfigured);
    },

    setConnectStatus: ({ commit }: ActionContext<TChatStoreState, TAppStoreState>, connectStatus: ChatConnectStatus): void => {
      commit('setConnectStatus', connectStatus);
    },

    setLastError: ({ commit }: ActionContext<TChatStoreState, TAppStoreState>, error: Error): void => {
      commit('setLastError', error);
    },

    joinGroupStart: ({ commit }: ActionContext<TChatStoreState, TAppStoreState>, group: TChatGroup): void => {
      commit('joinGroupStart', group);
    },

    setMessages: ({ commit }: ActionContext<TChatStoreState, TAppStoreState>, params: { groupId: string; messages: TChatMessage[] }): void => {
      commit('setMessages', params);
    },

    joinGroupEnd: ({ commit }: ActionContext<TChatStoreState, TAppStoreState>, groupId: string): void => {
      commit('joinGroupEnd', groupId);
    },

    appendMessages: ({ commit }: ActionContext<TChatStoreState, TAppStoreState>, messages: TChatMessage[]): void => {
      commit('appendMessages', messages);
    },

  },
  mutations: {

    setIsConfigured(state: TChatStoreState, isConfigured: boolean): void {
      state.isConfigured = isConfigured;
      if (!state.isConfigured) {
        state.connectStatus = ChatConnectStatus.DISCONNECTED;
        state.isKickedOut = false;
        state.kickedOutReason = null;
        state.lastError = null;
      }
    },

    setConnectStatus(state: TChatStoreState, connectStatus: ChatConnectStatus): void {
      state.connectStatus = connectStatus;
      if (!state.isConfigured) {
        state.isKickedOut = false;
        state.kickedOutReason = null;
        state.lastError = null;
      }
    },

    setLastError(state: TChatStoreState, error: Error): void {
      state.lastError = error;
    },

    joinGroupStart(state: TChatStoreState, group: TChatGroup): void {
      const existingGroupState = state.groups.find(groupState => groupState.group.id === group.id);
      if (existingGroupState) {
        return;
      }
      state.groups.push({
        group: group,
        messages: [],
        isJoining: true,
      });
    },

    setMessages(state: TChatStoreState, params: { groupId: string; messages: TChatMessage[] }): void {
      const { groupId, messages } = params;
      if (!messages.length) {
        return;
      }
      const existingGroupState = state.groups.find(groupState => groupState.group.id === groupId);
      if (!existingGroupState) {
        return;
      }
      existingGroupState.messages = [ ...filterMessages(messages) ];
    },

    joinGroupEnd(state: TChatStoreState, groupId: string): void {
      const existingGroupState = state.groups.find(groupState => groupState.group.id === groupId);
      if (!existingGroupState) {
        return;
      }
      existingGroupState.isJoining = false;
    },

    appendMessages(state: TChatStoreState, messages: TChatMessage[]): void {
      if (!messages.length) {
        return;
      }
      const filteredMessages = filterMessages(messages);
      if (!filteredMessages.length) {
        return;
      }

      messages.forEach(message => {
        state.groups.forEach(groupState => {
          if (
            message.to === groupState.group.id
            || message.type === ChatMessageType.CUSTOM
          ) {
            groupState.messages = groupState.messages.concat([ message ]);
          }
        });
      });

      // TChatStoreState
    },

  },
};

export default chatStore;
