import api from '../../api/index';

export default {
  strict: true,
  namespaced: true,
  state: {
    user_info: {}
  },
  getters: {
    get: function (state) {
      return state.user;
    }
  },
  mutations: {
    set(state, val) {
      state.user = val;
    },
    setUserInfo(state, data) {
      state.user_info = data;
    }
  },
  actions: {
    getWeather: async function ({commit}) {
      let user;
      try {
        user = await api.weather.forCity("kiev");
      } catch (e) {
        alert(e, 'err')
      }

      commit('set', user.data.name);
    },
    editUserInfo: async function ({commit}, payload) {
      await api.users.editUserInfo(payload)
        .then((resolve) => {
          // commit('setPromoPage', resolve);
        }).catch((e) => {
          // commit('promoPageError', e.data);
        });
    },

    getUserInfo: async function ({commit}) {
      await api.users.getUserInfo()
        .then((resolve) => {
          commit('setUserInfo', resolve);
        }).catch((e) => {
          // commit('promoPageError', e.data);
        });
    },
    getUserProfile: async function ({commit}, payload) {
      await api.users.getUserInfo(payload)
        .then(() => {
          // commit('setPromoPage', resolve);
        }).catch((e) => {
          // commit('promoPageError', e.data);
        });
    },
    createUserCompany: async function ({commit}, payload) {
      await api.users.createUserCompany(payload)
        .then((resolve) => {
          // commit('setPromoPage', resolve);
        }).catch((e) => {
          // commit('promoPageError', e.data);
        });
    },
    deleteUserCompany: async function ({commit}, payload) {
      await api.users.deleteUserCompany(payload)
        .then((resolve) => {
          /* nothing */
        }).catch((e) => {
          // commit('promoPageError', e.data);
        });
    },
    editUserCompany: async function ({commit}, payload) {
      await api.users.editUserCompany(payload)
        .then((resolve) => {
          // commit('setPromoPage', resolve);
        }).catch((e) => {
          // commit('promoPageError', e.data);
        });
    },
    uploadFile: async function ({commit}, payload) {
      await api.users.uploadFile(payload)
        .then((resolve) => {
          // commit('setPromoPage', resolve);
        }).catch((e) => {
          // commit('promoPageError', e.data);
        });
    },
    deleteFile: async function ({commit}, payload) {
      await api.users.deleteFile(payload)
        .then((resolve) => {
          // commit('setPromoPage', resolve);
        }).catch((e) => {
          // commit('promoPageError', e.data);
        });
    },
  }
}

