import BaseApi from '@/_types/api/base.api.class';
import { AxiosRequestConfig, CancelToken } from 'axios';
import { TMessagesList } from '@/_types/messages-list.type';

export type TCallMessageListParams = {
  eventId: number;
}

export type TCallUserMessageListParams = {
  eventId: number;
  userId: number;
}
export type TSendMessageParams = {
  eventId: number;
  userId: number;
  text: string;
}

export class MessagesApi extends BaseApi {

  public async callMessageList(params: TCallMessageListParams, cancelToken?: CancelToken): Promise<TMessagesList> {
    const { eventId } = params;
    const url = `/event/${eventId}/message/list`;
    const config: AxiosRequestConfig = {
      url: url,
      method: 'GET',
      cancelToken,
    };

    let response;

    try {
      response = await this.axios.request<TMessagesList>(config);
    } catch (error) {
      throw this.responseError(error);
    }
    return response.data;
  }

  public async callUserMessageList(params: TCallUserMessageListParams, cancelToken?: CancelToken): Promise<TMessagesList> {
    const { eventId, userId } = params;
    const url = `/event/${eventId}/user/${userId}/message/list`;
    const config: AxiosRequestConfig = {
      url: url,
      method: 'GET',
      cancelToken,
    };

    let response;

    try {
      response = await this.axios.request<TMessagesList>(config);
    } catch (error) {
      throw this.responseError(error);
    }
    return response.data;
  }

  public async sendMessage(params: TSendMessageParams, cancelToken?: CancelToken): Promise<void> {
    const { eventId, userId, text } = params;
    const url = `/event/${eventId}/user/${userId}/message`;

    const config: AxiosRequestConfig = {
      url: url,
      params: { text },
      method: 'PUT',
      cancelToken,
    };
    let response;

    try {
      response = await this.axios.request<void>(config);
    } catch (error) {
      throw this.responseError(error);
    }

    return response.data;
  }

}

const messagesApi = new MessagesApi();
export default messagesApi;
