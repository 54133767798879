

















































































































import Component from 'vue-class-component';
import { Vue } from 'vue-property-decorator';
import CookiesHelper from '@/_helpers/cookies-helper';

const CONSENT_COOKIE_NAME = 'biletumConsentCookie';
const CONSENT_COOKIE_VALUE = '1';

@Component({
  name: 'cookie-consent-box',
  components: {},
  computed: {
    cookieConsented(): boolean {
      return CookiesHelper.readCookie(CONSENT_COOKIE_NAME) === CONSENT_COOKIE_VALUE;
    },
    isEnglishTextShown(): boolean {
      return this.$i18n.locale !== 'ru';
    },
    isRussianTextShown(): boolean {
      return this.$i18n.locale === 'ru';
    }
  }
})
export default class App extends Vue {

  public mainShowFlag: boolean = false;
  public isLearnMoreTextShown: boolean = false;

  public mounted(): void {
    this.mainShowFlag = (window as Window).top === (window as Window).self;
  }

  private setCookieConsent(): void {
    this.mainShowFlag = false;
    CookiesHelper.createCookie({
      name: CONSENT_COOKIE_NAME,
      value: CONSENT_COOKIE_VALUE,
      maxAge: 365 * 24 * 60 * 60,
    });
  }

  private toggleLearnMoreText(): void {
    this.isLearnMoreTextShown = !this.isLearnMoreTextShown;
  }

}
