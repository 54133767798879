import { AxiosRequestConfig, AxiosResponse, CancelToken } from 'axios';
import BaseApi from '@/_types/api/base.api.class';
import {
  TCreatePromoPage,
  TPromoPage,
  TCodeList,
} from '@/_types/promo-page/promo-page.type';
import {TLivePage, TLivePageGetRequest, TLivePageResponse} from '@/_types/promo-page/live-page.type';
import { TApiListResponse } from '@/_types/api/api-list-response.type';

export type TGetContactPromoPageParams = {
  eventId: number;
  contactId: number;
}

export type TCreatePromoPageParams = {
  eventId: number;
  promoPageData: TCreatePromoPage;
}

export type TDeletePromoPageParams = {
  eventId: number;
  externalId: string;
}

export type TDeletePromoPageByCodeParams = {
  eventId: number;
  externalId: string;
  codeId: number;
}

export type TGetAllEventPromoPagesParams = {
  eventId: number;
};

export type TPromopageCodeListParams = {
  eventId: number;
};

export type TGetLivePageParams = {
  eventId: number;
}

export type TPatchLivePageParams = {
  eventId: number;
  livePageData: TLivePage;
}

export type TLivePageAgendaItemResponse = {
  title: string;
}

export type TLivePageAgendaItem = {
  isEditing: boolean;
  toDelete: boolean;
  data: TLivePageAgendaItemResponse;
}

export class PromoPageApi extends BaseApi {

  public async getContactPromoPage(params: TGetContactPromoPageParams, cancelToken?: CancelToken): Promise<TPromoPage> {
    const { eventId, contactId } = params;
    const config: AxiosRequestConfig = {
      url: `/event/${eventId}/contact/${contactId}/promopage`,
      method: 'GET',
      cancelToken,
    };

    let response;
    try {
      response = await this.axios.request<TPromoPage>(config);
    } catch (error) {
      throw this.responseError(error);
    }
    return response.data;
  }

  public async createPromoPage(params: TCreatePromoPageParams, cancelToken?: CancelToken): Promise<TPromoPage> {
    const { eventId, promoPageData } = params;

    const config: AxiosRequestConfig = {
      url: `/event/${eventId}/promopage/create`,
      method: 'POST',
      params: promoPageData,
      cancelToken,
    };

    let response;
    try {
      response = await this.axios.request<TPromoPage>(config);
    } catch (error) {
      throw this.responseError(error);
    }
    return response.data;
  }

  public async promoPageCodeList(params: TPromopageCodeListParams, cancelToken?: CancelToken): Promise<TApiListResponse<TCodeList[]>> {
    const { eventId } = params;

    const config: AxiosRequestConfig = {
      url: `/event/${eventId}/promopage_code/list`,
      method: 'GET',
      cancelToken,
    };

    let response;
    try {
      response = await this.axios.request<TApiListResponse<TCodeList[]>>(config);
    } catch (error) {
      throw this.responseError(error);
    }
    return response.data;
  }

  public async deletePromoPage(params: TDeletePromoPageParams, cancelToken?: CancelToken): Promise<void> {
    const { eventId, externalId } = params;

    const config: AxiosRequestConfig = {
      url: `/event/${eventId}/promopage/${externalId}`,
      method: 'DELETE',
      cancelToken,
    };

    let response;
    try {
      response = await this.axios.request<void>(config);
    } catch (error) {
      throw this.responseError(error);
    }
    return response.data;
  }

  public async deletePromoPageByCode(params: TDeletePromoPageByCodeParams, cancelToken?: CancelToken): Promise<void> {
    const { eventId, externalId, codeId } = params;

    const config: AxiosRequestConfig = {
      url: `/event/${eventId}/promopage/${externalId}/code/${codeId}`,
      method: 'DELETE',
      cancelToken,
    };

    let response;
    try {
      response = await this.axios.request<void>(config);
    } catch (error) {
      throw this.responseError(error);
    }
    return response.data;
  }

  public async getAllEventPromoPages(params: TGetAllEventPromoPagesParams, cancelToken?: CancelToken): Promise<TPromoPage[]> {
    const { eventId } = params;
    const config: AxiosRequestConfig = {
      url: `/event/${eventId}/promopage/list/all`,
      method: 'GET',
      cancelToken,
    };

    let response;
    try {
      response = await this.axios.request<TApiListResponse<TPromoPage>>(config);
    } catch (error) {
      throw this.responseError(error);
    }
    return response.data.List;
  }

  public async getLivePage(params: TGetLivePageParams, cancelToken?: CancelToken): Promise<TLivePage> {
    const { eventId } = params;
    const config: AxiosRequestConfig = {
      url: `/event/${eventId}/live`,
      method: 'GET',
      cancelToken,
    };

    let response;
    const mappedResponseData: TLivePage = {
      title: '',
      descr: '',
    };
    try {
      response = await this.axios.request<TLivePageResponse>(config);
      // Adapt response type to TLivePage. This code is good
      if (response && response.data) {
        mappedResponseData.title = response.data.title || '';
        mappedResponseData.descr = response.data.descr || '';
        mappedResponseData.video_player_frame = response.data.video_player_frame || '';
        mappedResponseData.video_file_url = response.data.video_file_url || '';
        mappedResponseData.agenda = (response.data.agenda && response.data.agenda.length) ? response.data.agenda.map((x: TLivePageAgendaItemResponse): TLivePageAgendaItem => {
          return {
            isEditing: false,
            toDelete: false,
            data: {
              ...x
            }
          };
        }) : [];
        mappedResponseData.agenda_video_player_frame = response.data.agenda_video_player_frame || '';
        mappedResponseData.agenda_video_file_url = response.data.agenda_video_file_url || '';
        mappedResponseData.photos = response.data.photos || [];
        mappedResponseData.show_title = response.data.show_title;
        mappedResponseData.show_event_program = response.data.show_event_program;
        mappedResponseData.show_sponsors = response.data.show_sponsors;
        mappedResponseData.show_event_news = response.data.show_event_news;
        mappedResponseData.show_streaming = response.data.show_streaming;
      }
    } catch (error) {
      throw this.responseError(error);
    }
    return mappedResponseData;
  }

  public async patchLivePage(params: TPatchLivePageParams, cancelToken?: CancelToken): Promise<number> {
    const { eventId, livePageData } = params;
    // TODO: remove me when GET agenda is the same as PATCH agenda
    // This adapts the agenda for the GET request
    // Hardcode for the win.
    const livePageDataGetRequest: TLivePageGetRequest = {
      title: livePageData.title,
      descr: livePageData.descr,
      video_player_frame: livePageData.video_player_frame,
      video_file_url: livePageData.video_file_url,
      agenda_video_player_frame: livePageData.agenda_video_player_frame,
      agenda_video_file_url: livePageData.agenda_video_file_url,
      photos: livePageData.photos,
      show_streaming: livePageData.show_streaming,
      show_title: livePageData.show_title,
      show_event_program: livePageData.show_event_program,
      show_sponsors: livePageData.show_sponsors,
      show_event_news: livePageData.show_event_news,
    };
    // This mapping is the reason of all this adaptation (together with TypeScript)
    if (livePageData.agenda) {
      livePageDataGetRequest.agenda = livePageData.agenda.map((x: TLivePageAgendaItem): string => x.data.title);
    }
    // Adaptation ends.
    const config: AxiosRequestConfig = {
      url: `/event/${eventId}/live`,
      method: 'PATCH',
      params: livePageDataGetRequest,
      cancelToken,
    };

    let response;
    try {
      response = await this.axios.request<AxiosResponse>(config);
    } catch (error) {
      throw this.responseError(error);
    }
    return response.status;
  }

}

const promoPageApi = new PromoPageApi();
export default promoPageApi;
