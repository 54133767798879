import Vue from 'vue';
import { Route } from 'vue-router';
import { NavigationGuardNext } from 'vue-router/types/router';

import store from '@/store';
import router from '@/router';
import i18n from '@/i18n';
import helpCrunchService from '@/_services/help-crunch.service';

export default class RouterHelper {

  public static authorized(to: Route, from: Route, next: NavigationGuardNext): void {
    if (!store.state.authStore.token) {
      router.push({ name: 'auth-page', params: { redirect: to.path } });
      return;
    }
    next();
  }

  public static setLang(to: Route, from: Route, next: NavigationGuardNext): void {
    if ([ 'en', 'ru', 'lt', 'zh', 'uk' ].indexOf(to.params.lang) === -1) {
      router.push({
        name: to.name || undefined,
        params: Object.assign({}, to.params, { lang: i18n.locale }),
      });
      return;
    }

    if (i18n.locale !== to.params.lang) {
      i18n.locale = to.params.lang;
      switch (to.params.lang) {
        case 'ru':
          Vue.moment.locale('ru');
          break;
        case 'lt':
          Vue.moment.locale('lt');
          break;
        case 'zh':
          Vue.moment.locale('zh');
          break;
        case 'uk':
          Vue.moment.locale('uk');
          break;
        default:
          Vue.moment.locale('en');
      }
      helpCrunchService.locale = to.params.lang;
    }

    next();
  }

}
