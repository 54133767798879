import api from "../../api";

export default {
  strict: true,
  namespaced: true,
  state: {
    noteList: [],
    noteListPromoPage: [],
    noteError: ''
  },
  getters: {},
  mutations: {
    noteError(state, data) {
      state.noteError = data
    },
    setNoteList(state, data) {
      // state.noteList = data;
      let list = data.List.map((item) => {
        return {
          created_at: item.created_at,
          id: item.id,
          is_creator: item.is_creator,
          is_favorite: item.is_favorite,
          is_private: item.is_private,
          text: item.text || '',
          title: item.title,
          updated_at: item.updated_at,
          owner: {
            birthday: item.owner.birthday,
            city: item.owner.city,
            companies: item.owner.companies,
            country: item.owner.country,
            email: item.owner.email,
            facebook: item.owner.facebook,
            files: item.owner.files,
            name: item.owner.name,
            id: item.owner.id,
            instagram: item.owner.instagram,
            surname: item.owner.surname,
            middle_name: item.owner.middle_name,
            organization: item.owner.organization,
            phone: item.owner.phone,
            photo_url: item.owner.photo_url,
            position: item.owner.position,
            skype: item.owner.skype,
            telegram: item.owner.telegram,
            twitter: item.owner.twitter,
            viber: item.owner.viber,
            wechat: item.owner.wechat,
          },
        }
      }).reverse();

      state.noteList = {
        Limit: data.Limit,
        Offset: data.Offset,
        Total: data.Total,
        List: list,
      };
    },
    setNoteListPromoPage(state, data) {
      // state.noteList = data;
      let list = data.List.map((item) => {
        return {
          created_at: item.created_at,
          id: item.id,
          is_creator: item.is_creator,
          is_favorite: item.is_favorite,
          is_private: item.is_private,
          text: item.text || '',
          title: item.title,
          updated_at: item.updated_at,
          owner: {
            birthday: item.owner.birthday,
            city: item.owner.city,
            companies: item.owner.companies,
            country: item.owner.country,
            email: item.owner.email,
            facebook: item.owner.facebook,
            files: item.owner.files,
            first_name: item.owner.name,
            id: item.owner.id,
            instagram: item.owner.instagram,
            last_name: item.owner.surname,
            middle_name: item.owner.middle_name,
            organization: item.owner.organization,
            phone: item.owner.phone,
            photo_url: item.owner.photo_url,
            position: item.owner.position,
            skype: item.owner.skype,
            telegram: item.owner.telegram,
            twitter: item.owner.twitter,
            viber: item.owner.viber,
            wechat: item.owner.wechat,
          },
        }
      });

      state.noteListPromoPage = {
        Limit: data.Limit,
        Offset: data.Offset,
        Total: data.Total,
        List: list,
      };
    },
  },
  actions: {
    addNote: async function ({commit}, data) {
      try {
        await api.notes.addNote(data)
      } catch (e) {
        commit('noteError', e.data);
      }
    },
    editNote: async function ({commit}, data) {
      await api.notes.editNote(data)
        .catch((e) => {
          commit('noteError', e.data);
        });
    },
    removeNote: async function ({commit}, data) {
      await api.notes.removeNote(data)
        .then(() => {
        }).catch((e) => {
          commit('noteError', e.data);
        });
    },
    addFav: async function ({commit}, data) {
      await api.notes.addFav(data)
        .then(() => {
        }).catch((e) => {
          commit('noteError', e.data);
        });
    },
    removeFav: async function ({commit}, data) {
      await api.notes.removeFav(data)
        .then(() => {
        }).catch((e) => {
          commit('noteError', e.data);
        });
    },
    callNoteList: async function ({commit}, data) {
      await api.notes.noteList(data)
        .then((resolve) => {
          commit('setNoteList', resolve);
        }).catch((e) => {
          commit('noteError', e.data);
        });
    },
    callNoteListAll: async function ({commit}, data) {
      await api.notes.noteListAll(data)
        .then((resolve) => {
          commit('setNoteList', resolve);
        }).catch((e) => {
          commit('noteError', e.data);
        });
    },
    callNoteListAllPromoPage: async function ({commit}, data) {
      await api.notes.noteListAll(data)
        .then((resolve) => {
          commit('setNoteListPromoPage', resolve);
        }).catch((e) => {
          commit('noteError', e.data);
        });
    },
  }

}

