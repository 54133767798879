import { ActionContext, Module } from 'vuex';
import { TAppStoreState } from '@/_types/store/app-store-state.type';
import { TSideBarRightStoreState } from '@/_modules/promo/types/side-bar-right-store-state.type.ts';

const sideBarRightStore: Module<TSideBarRightStoreState, TAppStoreState> = {
  namespaced: true,
  state: {
    isOpen: false,
  },
  getters: {

    isOpen: (state: TSideBarRightStoreState): boolean => {
      return state.isOpen;
    },

  },
  actions: {

    open: ({ commit }: ActionContext<TSideBarRightStoreState, TAppStoreState>): void => {
      commit('open');
    },

    close: ({ commit }: ActionContext<TSideBarRightStoreState, TAppStoreState>): void => {
      commit('close');
    },

    toggle: ({ commit }: ActionContext<TSideBarRightStoreState, TAppStoreState>): void => {
      commit('toggle');
    },

  },
  mutations: {

    open(state: TSideBarRightStoreState): void {
      state.isOpen = true;
    },

    close(state: TSideBarRightStoreState): void {
      state.isOpen = false;
    },

    toggle(state: TSideBarRightStoreState): void {
      state.isOpen = !state.isOpen;
    },

  },
};

export default sideBarRightStore;
