


import Component from 'vue-class-component';
import { Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import { TEvent } from '@/_types/event.type';
// @ts-ignore
import LanguagePicker from '@/_components/language-picker/language-picker';
// import VueI18n from 'vue-i18n';
// import TranslateResult = VueI18n.TranslateResult;

@Component({
  name: 'event-head-panel',
  components: {
    LanguagePicker
  },
  computed: {
    ...mapGetters('_eventStore', {
      event: 'event'
    }),
  },
})
export default class EventHeadPanel extends Vue {

  // DATA

  // mapped
  public readonly event: TEvent;

  // COMPUTED
  public get endDateNeeded(): boolean {
    return this.$moment(this.event.date_start).format('LL') !== this.$moment(this.event.date_end).format('LL');
  }

  // PROPS

  // WATCH

  // HOOKS

  // METHODS

}
