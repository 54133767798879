<template>
  <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="13" cy="13" r="12" :style="stroke"/>
    <path d="M18.1036 8.74576L17.2782 7.92093L13.0245 12.1752L8.77024 7.92093L7.94482 8.74576L12.1991 13L7.94482 17.2543L8.77024 18.0791L13.0245 13.8248L17.2782 18.0791L18.1036 17.2543L13.8493 13L18.1036 8.74576Z" :style="fill"/>
  </svg>
</template>

<script>
import Vue from 'vue';

const IconCalendar = Vue.extend({
  name: 'icon-close',
  props: {
    color: {
      type: String,
      default: '#fff',
    },
  },
  computed: {
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    stroke() {
      return `stroke: ${this.color};`;
    },
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    fill() {
      return `fill: ${this.color};`;
    }
  },
});
export default IconCalendar;
</script>

<style scoped lang="scss">
/* stylelint-disable */
.cls-1 {
  fill: none;
  stroke: #000;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 0.7px;
}
</style>
