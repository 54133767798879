


import Component from 'vue-class-component';
import { Vue } from 'vue-property-decorator';
// TODO: allow assets import
// @ts-ignore
import DEFAULT_AVATAR from '@/assets/images/no-avatar-300x300.png';

@Component({
  name: 'avatar',
  props: {
    src: {
      type: String,
      default: '',
    },
    width: {
      type: Number,
      default: 48,
    },
    mode: {
      type: String,
      default: ''
    },
    entityName: {
      type: String,
      default: 'person' // person, company, event
    },
    borderRadiusMode: {
      type: String,
      default: '' // br10
    },
    initials: {
      type: String,
      default: ''
    },
  },
})
export default class Avatar extends Vue {

  public src: string;
  public width: number;
  public mode: string;
  public initials: string;

  public get computedSrc(): string {
    return this.src || DEFAULT_AVATAR;
  }

  public get style(): string {
    return `width: ${this.width}px; height: ${this.width}px;`;
  }

}
