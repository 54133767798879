export type TCreateCookieParams = {
  name: string;
  value: string | number;
  maxAge?: number;
};

const ENV = process.env.VUE_APP_ENV;
const COOKIE_PREFIX = process.env.VUE_APP_COOKIE_PREFIX || '';

export default class CookiesHelper {

  /* Creates a cookie with provided properties
   */
  public static createCookie(params: TCreateCookieParams): void {

    const {name, value, maxAge} = params;
    const nameWithPrefix: string = COOKIE_PREFIX + name;

    let domains: string[];
    if (ENV === 'development') {
      domains = [ 'local.eventswallet.com' ];
    } else if (ENV === 'dev') {
      domains = [ 'dev-web.eventswallet.com' ];
    } else {
      domains = [ 'eventswallet.com', 'eventswallet.fr', 'eventswallet.cn' ];
    }

    domains.forEach((domain: string) => {
      const parts: string[] = [];

      parts.push(nameWithPrefix + '=' + value);
      parts.push('domain=' + domain);
      parts.push('path=/');
      parts.push('secure');
      parts.push('samesite=strict');

      if (maxAge) {
        parts.push('max-age=' + maxAge);
      }

      document.cookie = parts.join(';');
    });
  }

  /* Reads a cookie by a given name
   */
  public static readCookie(name: string): string {

    const nameWithPrefix: string = COOKIE_PREFIX + name;

    const nameEQ: string = nameWithPrefix + '=';
    const ca: string[] = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c: string = ca[i];
      while (c.charAt(0) === ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0) {
        return c.substring(nameEQ.length, c.length);
      }
    }
    return '';
  }

  /* Removes a cookie with the given name
   * by setting its expires property to past date
   */
  public static deleteCookie(name: string): void {
    this.createCookie({ name, value: '', maxAge: -1 });
  }

}
