
























import Component from 'vue-class-component';
import { Vue, Watch } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import { TUser } from '@/_types/user.type';
import { TEvent } from '@/_types/event.type';
import { TContact } from '@/_types/contact.type';
import helpCrunchService, { THelpCrunchConfig } from '@/_services/help-crunch.service';
import Navigation from '@/_components/navigation/navigation.vue';
import AuthPopup from '@/_components/auth-popup/auth-popup.vue';
import { TPromoPage } from '@/_types/promo-page/promo-page.type';
import NotificationsPopUp from '@/_modules/notifications-popup/components/notifications-popup.vue';
import CookieConsentBox from '@/_components/cookie-consent-box/cookie-consent-box.vue';
import MobileAppInviter from '@/_components/mobile-app-inviter/mobile-app-inviter.vue';
import EventHeadPanel from '@/_modules/promo/components/event-head-panel/event-head-panel.vue';
import LanguagePicker from '@/_components/language-picker/language-picker.vue';

// TODO: ALMOST DONE get rid of id="app" - see public/index.html - use "app" classname instead

@Component({
  name: 'App', // should be the only exception with CamelCase
  components: { EventHeadPanel, Navigation, CookieConsentBox, NotificationsPopUp, AuthPopup, MobileAppInviter, LanguagePicker },
  computed: {
    ...mapGetters({
      isAuthenticated: 'authStore/isAuthenticated',
      isAuthPopupVisible: 'authStore/isAuthPopupVisible',
    }),
    ...mapGetters('_userStore', {
      user: 'user',
    }),
    ...mapGetters('_eventStore', {
      event: 'event',
    }),
    ...mapGetters('promoPageStore', {
      contact: 'contact',
      promoPage: 'promoPage',
    }),
    ...mapGetters('sideBarRightStore', {
      isSideBarRightOpen: 'isOpen',
    }),
  }
})
export default class App extends Vue {

  public isAuthenticated: boolean;
  public readonly isAuthPopupVisible: boolean;
  public user: TUser;
  public event: TEvent;
  public contact: TContact;
  public promoPage: TPromoPage;
  public readonly isSideBarRightOpen: boolean;

  public async created(): Promise<void> {
    // Установка атрибута lang в соотв. локаль, а то там всегда было en
    document.documentElement.lang = this.$i18n.locale;
    Vue.moment.locale(this.$i18n.locale);

    helpCrunchService.locale = this.$i18n.locale;
    this._configureHelpCrunch();
  }

  public get eventId(): number {
    return this.$route.params.eventId ? parseInt(this.$route.params.eventId, 10) : null;
  }

  public get helpCrunchConfig(): THelpCrunchConfig {
    return {
      user: this.user,
      event: this.event,
      contact: this.contact,
      promoPage: this.promoPage,
    };
  }

  public get isEventHeadPanelVisible(): boolean {
    return !!this.$route.matched.find(route => route.path.indexOf('/:lang/events/:eventId') >= 0);
  }

  public get isNavigationVisible(): boolean {

    if (this.isEventHeadPanelVisible) {
      return false;
    }

    if (
      this.$route.meta
      && typeof this.$route.meta.showNavigation !== 'undefined'
      && !this.$route.meta.showNavigation
    ) {
      return false;
    }

    return (
      this.$route.name
      // TODO: we already have meta.showNavigation - change route config instead
      && this.$route.name.indexOf('promo') < 0
      && this.$route.name !== 'notes-list'
      && this.$route.name.indexOf('result') < 0
    );
  }

  public get additionalRootElementClasses(): { [key: string]: boolean } {
    const result: { [key: string]: boolean } = {};
    if (!this.$route) {
      return result;
    }

    const replaces = [
      [ /\[:eventId\]/g, this.$route.params.eventId ],
    ];

    if (this.$route.name) {
      result['app-' + this.$route.name] = true;
    }

    if (this.$route.meta && this.$route.meta.rootElementClasses) {
      for (let i = 0; i < this.$route.meta.rootElementClasses.length; i++) {
        let classNameString = this.$route.meta.rootElementClasses[i];
        classNameString = ((str): string => {
          for (let repl = 0; repl < replaces.length; repl++) {
            str = str.replace(replaces[repl][0], replaces[repl][1]);
          }
          return str;
        })(classNameString);
        result[classNameString] = true;
      }
    }
    return result;
  }

  /* Do not remove - responsible for store(s) refresh */
  @Watch('eventId', { immediate: true })
  private _onEventIdChange(): void {
    this._refreshEventStore();
    this._refreshPromoPageStore();
  }

  /* Do not remove - responsible for store(s) refresh */
  @Watch('isAuthenticated', { immediate: true })
  private _onIsAuthenticatedChange(): void {
    this._refreshUserStore();
    this._refreshPromoPageStore();
  }

  @Watch('helpCrunchConfig', { immediate: true })
  private _onHelpCrunchConfigChange(): void {
    this._configureHelpCrunch();
  }

  @Watch('isSideBarRightOpen', { immediate: false })
  private _onIsSideBarRightOpenChange(newVal: boolean): void {
    const offset = newVal ? 330 : 0;
    try {
      helpCrunchService.setChatButtonOffset(70 + offset, 20);
    } catch {
      /* ignore */
    }
  }

  private _configureHelpCrunch(): void {
    helpCrunchService.configure(this.helpCrunchConfig);
    helpCrunchService.setChatButtonOffset(70, 20);
  }

  private _refreshUserStore(): void {
    if (this.isAuthenticated) {
      this.$store.dispatch('_userStore/getUser');
    } else {
      this.$store.dispatch('_userStore/reset');
    }
  }

  private _refreshEventStore(): void {
    if (this.eventId) {
      this.$store.dispatch('_eventStore/getEvent', this.eventId);
      // TODO: remove old store request
      this.$store.dispatch('eventStore/event', this.$route.params.eventId);
    } else {
      this.$store.dispatch('_eventStore/reset');
    }
  }

  private _refreshPromoPageStore(): void {
    if (this.isAuthenticated && this.eventId) {
      this.$store.dispatch('promoPageStore/getContact', this.eventId);
      this.$store.dispatch('promoPageStore/getPromoPage', this.eventId);
    } else {
      this.$store.dispatch('promoPageStore/reset');
    }
  }

}
