








import Vue from 'vue';

const Language = Vue.extend({
  name: 'language',
});

export default Language;
