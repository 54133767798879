import { TMediaItem } from '@/_modules/events/types/media-item.type';
import ValidationHelper from '@/_helpers/validation.helper';
import { TConferenceProgram } from '@/_modules/promo/types/conference-program.type';
import { TBroadcast } from '@/_types/broadcasts/broadcast.type';

export default class PromoProgramHelper {

  public static updateProgramMediaItems(program: TConferenceProgram, broadcastsByProgramId: { [programId: string]: TBroadcast[] }): void {
    if (!program.mediaItems) {
      program.mediaItems = PromoProgramHelper._getPromoPageProgramMediaItems(program, broadcastsByProgramId);
      program.hasActiveBroadcast = !!program.mediaItems.find((mediaItem: TMediaItem): boolean => {
        return mediaItem.type === 'broadcast';
      });
      return;
    }

    const newItems = PromoProgramHelper._getPromoPageProgramMediaItems(program, broadcastsByProgramId);
    const newItemsIds: string[] = newItems.map((mediaItem: TMediaItem): string => {
      return mediaItem.id;
    });
    const currentItems = program.mediaItems;
    const currentItemsIds: string[] = [];
    for (let index = currentItems.length - 1; index > -1; index--) {
      if (newItemsIds.indexOf(currentItems[index].id) < 0) {
        currentItems.splice(index, 1);
      } else {
        currentItemsIds.push(currentItems[index].id);
      }
    }

    newItems.forEach((mediaItem: TMediaItem): void => {
      if (currentItemsIds.indexOf(mediaItem.id) < 0) {
        currentItems.push(mediaItem);
      }
    });

    program.hasActiveBroadcast = !!program.mediaItems.find((mediaItem: TMediaItem): boolean => {
      return mediaItem.type === 'broadcast';
    });
  }

  private static _getPromoPageProgramMediaItems(program: TConferenceProgram, broadcastsByProgramId: { [programId: string]: TBroadcast[] }): TMediaItem[] {

    const items: TMediaItem[] = [];

    const programBroadcasts = broadcastsByProgramId[program.id] || [];

    if (programBroadcasts && programBroadcasts.length) {
      programBroadcasts.forEach(broadcast => {
        items.push({
          id: `program_${program.id}_broadcast_${program.video_stream_embed}`,
          entityType: 'program',
          entityId: program.id,
          type: 'broadcast',
          src: broadcast.stream_url,
          isUnpinned: false,
          isActive: false,
        });
      });
    }

    if (program.video_stream_embed) {
      if (ValidationHelper.isValidVideoStreamEmbed(program.video_stream_embed)) {
        items.push({
          id: `program_${program.id}_embed_${program.video_stream_embed}`,
          entityType: 'program',
          entityId: program.id,
          type: 'embed',
          src: program.video_stream_embed,
          isUnpinned: false,
          isActive: false,
        });
      }
    }

    if (program.poster_url) {
      items.push({
        id: `program_${program.id}_image_${program.poster_url}`,
        entityType: 'program',
        entityId: program.id,
        type: 'image',
        src: program.poster_url,
        isUnpinned: false,
        isActive: false,
      });
    }

    if (program.vod_url) {
      items.push({
        id: `program_${program.id}_video_${program.vod_url}`,
        entityType: 'program',
        entityId: program.id,
        type: 'video',
        src: program.vod_url,
        isUnpinned: false,
        isPaused: true,
        isActive: false,
      });
    }

    return items;
  }

}
