
export default class ValidationHelper {

  public static isValidVideoStreamEmbed(value: string): boolean {
    if (value === null || value === undefined || value === '') {
      return true;
    }
    return (new RegExp('^<iframe(?:\\s)+[^>]*src=[^>]*((></iframe>)|(/>))$', 'im')).test(value);
  }

  public static isValidPhoneNumberLength(value: string, phone: string): boolean {
    if (!value || !phone) {
      return true;
    }
    const included = value.replace(/[^#\d+]/g, '');
    return (included.length === phone.length);
  }

  public static isValidPhoneNumber(value: string): boolean {
    if (value === null || value === undefined || value === '') {
      return true;
    }
    return (new RegExp(/^[[+]\d]?(?:[\d]*)$/, )).test(value);
  }

  public static isValidExternalId(string: string): boolean {

    const symbols = /[`~!@#$%^&*()|+=?;:'",\s<>{}[\]\\/]/g;

    return !string.match(symbols);

  }
}
