import { ActionContext, Module } from 'vuex';
import { TAppStoreState } from '@/_types/store/app-store-state.type';
import { TEventStoreState } from '@/_modules/events/types/event-store-state.type';
import { TEvent } from '@/_types/event.type';
import AxiosCancellableRequest from '@/_types/api/axios-cancellable-request.class';
import eventApi, { TGetEventParams } from '@/_modules/events/api/event/event.api';
import EventHelper from '@/_helpers/event.helper';
import { TMeetingRoomConfig } from '@/_modules/meeting-rooms/types/meeting-room-config.type';

const getEventRequest = new AxiosCancellableRequest<TGetEventParams, TEvent>(eventApi.getEvent.bind(eventApi));

const _eventStore: Module<TEventStoreState, TAppStoreState> = {
  namespaced: true,
  state: {
    eventId: null,
    eventEntity: {
      data: null,
      isLoading: false,
      error: null,
    },
    obsSettingsDialogConfig: null,
    zoomSettingsDialogConfig: null,
    embedCodeDialogConfig: null,
    isBroadcastTimeCheckDialogVisible: false,
  },
  getters: {
    eventId: (state: TEventStoreState): number => {
      return state.eventId;
    },
    event: (state: TEventStoreState): TEvent => {
      return state.eventEntity.data;
    },
    isLoading: (state: TEventStoreState): boolean => {
      return state.eventEntity.isLoading;
    },
    obsSettingsDialogConfig: (state: TEventStoreState): TMeetingRoomConfig => {
      return state.obsSettingsDialogConfig;
    },
    zoomSettingsDialogConfig: (state: TEventStoreState): TMeetingRoomConfig => {
      return state.zoomSettingsDialogConfig;
    },
    embedCodeDialogConfig: (state: TEventStoreState): TMeetingRoomConfig => {
      return state.embedCodeDialogConfig;
    },
    isBroadcastTimeCheckDialogVisible: (state: TEventStoreState): boolean => {
      return state.isBroadcastTimeCheckDialogVisible;
    },
  },
  actions: {
    reset: ({ commit }: ActionContext<TEventStoreState, TAppStoreState>): void => {
      commit('setEventId', null);
    },
    refresh: ({ commit, dispatch, state }: ActionContext<TEventStoreState, TAppStoreState>): void => {
      commit('refresh');
      dispatch('getEvent', state.eventId);
    },
    getEvent: async (context: ActionContext<TEventStoreState, TAppStoreState>, eventId: number): Promise<TEvent> => {
      const { commit, state } = context;

      if (state.eventId !== eventId) {
        commit('setEventId', eventId);
      }

      if (state.eventEntity.data) {
        return state.eventEntity.data;
      }

      if (state.eventEntity.isLoading) {
        try {
          return await getEventRequest.promise;
        } catch (error) {
          return null;
        }
      }

      commit('eventRequest');
      let data;
      let isRedirected = false;
      try {
        data = await getEventRequest.load({ eventId });

        // If the event has .event_domain, check it and redirect if it is fine.
        isRedirected = EventHelper.redirectToEventDomain(data);

        return data;
      } catch (error) {
        commit('eventError', error);
        return null;
      } finally {
        if (!isRedirected) {
          commit('event', data);
        }
      }
    },

    setObsSettingsDialogConfig({ commit }: ActionContext<TEventStoreState, TAppStoreState>, obsSettingsDialogConfig: TMeetingRoomConfig): void {
      commit('setObsSettingsDialogConfig', obsSettingsDialogConfig);
    },

    setZoomSettingsDialogConfig({ commit }: ActionContext<TEventStoreState, TAppStoreState>, zoomSettingsDialogConfig: TMeetingRoomConfig): void {
      commit('setZoomSettingsDialogConfig', zoomSettingsDialogConfig);
    },

    setEmbedCodeDialogConfig({ commit }: ActionContext<TEventStoreState, TAppStoreState>, embedCodeDialogConfig: TMeetingRoomConfig): void {
      commit('setEmbedCodeDialogConfig', embedCodeDialogConfig);
    },

    setIsBroadcastTimeCheckDialogVisible({ commit }: ActionContext<TEventStoreState, TAppStoreState>, isBroadcastTimeCheckDialogVisible: boolean): void {
      commit('setIsBroadcastTimeCheckDialogVisible', isBroadcastTimeCheckDialogVisible);
    },
  },
  mutations: {

    refresh(state: TEventStoreState): void {
      state.eventEntity.data = null;
      state.eventEntity.isLoading = false;
      state.eventEntity.error = null;
      state.obsSettingsDialogConfig = null;
      state.zoomSettingsDialogConfig = null;
      state.embedCodeDialogConfig = null;
      state.isBroadcastTimeCheckDialogVisible = false;
    },

    setEventId(state: TEventStoreState, eventId: number): void {
      if (!eventId || eventId !== state.eventId) {
        getEventRequest.cancel('Canceled by store');
      }
      state.eventId = eventId || null;
      state.eventEntity.data = null;
      state.eventEntity.isLoading = false;
      state.eventEntity.error = null;
      state.obsSettingsDialogConfig = null;
      state.zoomSettingsDialogConfig = null;
      state.embedCodeDialogConfig = null;
      state.isBroadcastTimeCheckDialogVisible = false;
    },

    eventRequest(state: TEventStoreState): void {
      state.eventEntity.data = null;
      state.eventEntity.isLoading = true;
      state.eventEntity.error = null;
    },

    eventError(state: TEventStoreState, error: Error): void {
      state.eventEntity.error = error;
    },

    event(state: TEventStoreState, event: TEvent): void {
      state.eventEntity.data = event || null;
      state.eventEntity.isLoading = false;
    },

    setObsSettingsDialogConfig: (state: TEventStoreState, obsSettingsDialogConfig: TMeetingRoomConfig): void => {
      state.obsSettingsDialogConfig = obsSettingsDialogConfig;
      state.zoomSettingsDialogConfig = null;
      state.embedCodeDialogConfig = null;
      state.isBroadcastTimeCheckDialogVisible = false;
    },

    setZoomSettingsDialogConfig: (state: TEventStoreState, zoomSettingsDialogConfig: TMeetingRoomConfig): void => {
      state.obsSettingsDialogConfig = null;
      state.zoomSettingsDialogConfig = zoomSettingsDialogConfig;
      state.embedCodeDialogConfig = null;
      state.isBroadcastTimeCheckDialogVisible = false;
    },

    setEmbedCodeDialogConfig: (state: TEventStoreState, embedCodeDialogConfig: TMeetingRoomConfig): void => {
      state.obsSettingsDialogConfig = null;
      state.zoomSettingsDialogConfig = null;
      state.embedCodeDialogConfig = embedCodeDialogConfig;
      state.isBroadcastTimeCheckDialogVisible = false;
    },

    setIsBroadcastTimeCheckDialogVisible: (state: TEventStoreState, isBroadcastTimeCheckDialogVisible: boolean): void => {
      state.obsSettingsDialogConfig = null;
      state.zoomSettingsDialogConfig = null;
      state.embedCodeDialogConfig = null;
      state.isBroadcastTimeCheckDialogVisible = isBroadcastTimeCheckDialogVisible;
    },

  },
};

export default _eventStore;
