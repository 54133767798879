import { AxiosRequestConfig, CancelToken } from 'axios';
import BaseApi from '@/_types/api/base.api.class';
import { TEvent } from '@/_types/event.type';
import { TContact } from '@/_types/contact.type';
import { TListOfBroadcastsItem } from '@/_types/list-of-broadcasts.type';
import { TApiListResponse } from '@/_types/api/api-list-response.type';

export type TGetEventParams = {
  eventId: number;
}

export type TGetEventContactParams = {
  eventId: number;
}

export class EventApi extends BaseApi {

  public async getEvent(params: TGetEventParams, cancelToken?: CancelToken): Promise<TEvent> {
    const { eventId } = params;
    const config: AxiosRequestConfig = {
      url: `event/${eventId}`,
      method: 'GET',
      cancelToken,
    };

    let response;
    try {
      response = await this.axios.request<TEvent>(config);
    } catch (error) {
      throw this.responseError(error);
    }

    return response.data;
  }

  public async getContact(params: TGetEventContactParams, cancelToken?: CancelToken): Promise<TContact> {
    const { eventId } = params;
    const config: AxiosRequestConfig = {
      url: `event/${eventId}/contact/info`,
      method: 'GET',
      cancelToken,
    };

    let response;
    try {
      response = await this.axios.request<TContact>(config);
    } catch (error) {
      throw this.responseError(error);
    }

    return response.data;
  }

  public async getListOfBroadcasts(params: TGetEventContactParams, cancelToken?: CancelToken): Promise<TApiListResponse<TListOfBroadcastsItem>> {
    const { eventId } = params;
    const config: AxiosRequestConfig = {
      url: `event/${eventId}/stream/list`,
      method: 'GET',
      cancelToken,
    };

    let response;
    try {
      response = await this.axios.request<TApiListResponse<TListOfBroadcastsItem>>(config);
    } catch (error) {
      throw this.responseError(error);
    }

    return response.data;
  }

}

const eventApi = new EventApi();
export default eventApi;
