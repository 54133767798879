import { ActionContext, Module } from 'vuex/types';
import { TEditFormStoreState, TLeaveFunction } from '@/_modules/promo/types/edit-form-store-state.type.ts';
import { TAppStoreState } from '@/_types/store/app-store-state.type.ts';

const editFormStore: Module<TEditFormStoreState, TAppStoreState> = {
  namespaced: true,
  state: {
    formHasUnsavedChanges: false,
    isConfirmLeavePopupShown: false,
    leaveAction: (): void => {
      /* do nothing */
    },
  },
  getters: {
    hasUnsavedChanges: (state: TEditFormStoreState): boolean => {
      return state.formHasUnsavedChanges;
    },
    isConfirmLeavePopupShown: (state: TEditFormStoreState): boolean => {
      return state.isConfirmLeavePopupShown;
    },
  },
  actions: {
    setHasUnsavedChanges: ({ commit }: ActionContext<TEditFormStoreState, TAppStoreState>, hasUnsavedChanges: boolean): void => {
      commit('setHasUnsavedChanges', hasUnsavedChanges);
    },
    showConfirmLeavePopup: ({ commit }: ActionContext<TEditFormStoreState, TAppStoreState>, leave?: TLeaveFunction): void => {
      commit('setConfirmLeavePopupDisplayed', true);
      if (leave) {
        commit('setLeaveAction', leave);
      }
    },
    hideConfirmLeavePopup: ({ commit }: ActionContext<TEditFormStoreState, TAppStoreState>): void => {
      commit('setConfirmLeavePopupDisplayed', false);
    },
    leave: ({ state }: ActionContext<TEditFormStoreState, TAppStoreState>): void => {
      if (typeof state.leaveAction === 'function') {
        state.leaveAction();
      }
    }
  },
  mutations: {
    setHasUnsavedChanges(state: TEditFormStoreState, value: boolean): void {
      state.formHasUnsavedChanges = value;
    },
    setConfirmLeavePopupDisplayed(state: TEditFormStoreState, value: boolean): void {
      state.isConfirmLeavePopupShown = value;
    },
    setLeaveAction(state: TEditFormStoreState, value: any): void {
      state.leaveAction = value;
    },
  },
};

export default editFormStore;
