// TODO: refactoring - split into separate files

export enum EventAccessType {
  FREE = 'free',
  REGISTER = 'register',
}

export type TEventPersonal = {
  notes: number;
  contacts: number;
  tickets: number;
  is_creator: boolean;
  is_favorite: boolean;
  is_going: boolean;
  has_access: boolean;
}

export type TEventMap = {
  CountryName: string;
  CityName: string;
  Location: string;
  Latitude: string;
  Longitude: string;
}

export type TEvent = {
  id: number;
  title: string;
  date_start: string;
  date_end: string;
  access_type: EventAccessType;
  is_private: boolean;
  image: string;
  org_logo: string;
  address: string;
  personal: TEventPersonal;
  creator_user_id: number;
  event_domain: string;
  languages: string[];
}

/*
{
  "id":161,
  "title":"Roman Test Event Two",
  "date_start":"2020-08-17T11:00:00Z",
  "date_end":"2020-09-30T20:00:00Z",
  "event_type":{},
  "access_type":"register",
  "is_private":true,
  "image":"https://biletum.s3-eu-west-1.amazonaws.com/88/ad9c9f5d-82bc-4c3e-9251-ddfa9932a768.jpg",
  "org_logo":"",
  "address":"вулиця Велика Васильківська, 55, Київ, 02000",
  "personal":{
    "notes":4,
    "contacts":1,
    "tickets":0,
    "is_creator":true,
    "is_favorite":false,
    "is_going":true,
    "has_access":true
  },
  "map":{
    "CountryName":null,
    "CityName":null,
    "Location":null,
    "Latitude":"",
    "Longitude":""
    },
  "creator_user_id":88
}
 */
