<template>
  <svg width="17" height="12" viewBox="0 0 17 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd"
          id="eye"
          d="M16.8842 5.37431C15.3529 2.27928 12.1602 0.129059 8.47873 0.129059C4.79729 0.129059 1.60452 2.27928 0.0733032 5.37431C-0.0244344 5.56978 -0.0244344 5.79784 0.0733032 5.96073C1.60452 9.05576 4.79729 11.206 8.47873 11.206C12.1602 11.206 15.3529 9.05576 16.8842 5.96073C16.9819 5.76526 16.9819 5.56978 16.8842 5.37431V5.37431Z"
          fill="black"/>
    <path
      id="iris-outline-full"
      d="M8.47922 8.62656C6.83707 8.62656 5.52051 7.31 5.52051 5.66785C5.52051 4.0257 6.83707 2.70914 8.47922 2.70914C10.1214 2.70914 11.4379 4.0257 11.4379 5.66785C11.4379 7.31 10.1214 8.62656 8.47922 8.62656Z"
      stroke="white" stroke-width="1.25"/>
    <path
      id="iris-outline-half"
      d="M4.58474 5.02756C4.58474 6.64924 6.31934 7.9519 8.47873 7.9519C10.6381 7.9519 12.3727 6.64924 12.3727 5.02756"
      stroke="white" stroke-width="1.25"/>
  </svg>
</template>

<script>
import Vue from 'vue';

const IconPasswordEye = Vue.extend({
  name: 'icon-analytics',
});
export default IconPasswordEye;
</script>
