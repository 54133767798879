export enum DateTimeFormat {
  API_DATE_SMALL = 'YYYY-MM-DDTHH:mm',
  API_DATE_MEDIUM = 'YYYY-MM-DDTHH:mm:ss',
  DATE_MEDIUM = 'YYYY-MM-DD HH:mm:ss',
  MONTH_DATE = 'MMMM D',
  SHORT_DATE = 'L HH:mm',
  ONLY_DATE = 'L',
  YYYY_MM_DD = 'YYYY-MM-DD',
  HH_MM = 'HH:mm'
}
