import { MeetingRoomType } from '@/_modules/meeting-rooms/types/meeting-room-type.enum';
import { TMeetingRoomConfig } from '@/_modules/meeting-rooms/types/meeting-room-config.type';
import CryptoJS from 'crypto-js';
import { BroadcastType } from '@/_types/broadcasts/broadcast-type.enum';

// const MAX_MEETING_ROOM_ID = 4294967295;
const MAX_MEETING_ROOM_ID_PREFIXED = 429496729;
const LVB_PUSH_URL = process.env.VUE_APP_TENCENT_LVB_PUSH_URL;
const LVB_PUSH_PROTECTION_KEY = process.env.VUE_APP_TENCENT_LVB_PUSH_PROTECTION_KEY;
const MEETING_APP_ID = parseInt(process.env.VUE_APP_TENCENT_MEETING_APP_ID, 10);
const STREAMING_APP_ID = parseInt(process.env.VUE_APP_TENCENT_STREAMING_APP_ID, 10);
const PROGRAM_APP_ID = parseInt(process.env.VUE_APP_TENCENT_PROGRAM_APP_ID, 10);

export default class MeetingRoomsHelper {

  public static getBroadcastName(meetingRoomConfig: TMeetingRoomConfig): string {
    const { type } = meetingRoomConfig;
    switch (type) {
      case MeetingRoomType.BROADCAST: {
        const appId = MeetingRoomsHelper.getTrtcAppId(meetingRoomConfig);
        const roomId = MeetingRoomsHelper.getMeetingRoomId(meetingRoomConfig);
        const localTrtcUserId = MeetingRoomsHelper.getLocalTrtcUserId(meetingRoomConfig);
        return appId
          + '_' + roomId
          + '_' + localTrtcUserId
          + '_main';
      }

      default:
        return null;
    }
  }

  public static getBroadcastUrl(meetingRoomConfig: TMeetingRoomConfig): string {
    const { type } = meetingRoomConfig;
    switch (type) {
      case MeetingRoomType.BROADCAST: {
        return process.env.VUE_APP_TENCENT_LVB_PLAYBACK_URL
          + MeetingRoomsHelper.getBroadcastName(meetingRoomConfig)
          + '.m3u8';
      }

      default:
        return null;
    }
  }

  public static getTrtcAppId(meetingRoomConfig: TMeetingRoomConfig): number {
    const { type } = meetingRoomConfig;
    switch (type) {
      case MeetingRoomType.MEETING: {
        return MEETING_APP_ID;
      }

      case MeetingRoomType.BROADCAST: {
        if (meetingRoomConfig.broadcastType === BroadcastType.PROMO_CONTACT) {
          return STREAMING_APP_ID;
        } else if (meetingRoomConfig.broadcastType === BroadcastType.PROGRAM_SPEAKER) {
          return PROGRAM_APP_ID;
        } else {
          return null;
        }
      }

      default:
        return null;
    }
  }

  public static getMeetingRoomId(meetingRoomConfig: TMeetingRoomConfig): number {
    const { type } = meetingRoomConfig;
    switch (type) {
      case MeetingRoomType.MEETING: {
        const { meetingId } = meetingRoomConfig;
        return (meetingId % MAX_MEETING_ROOM_ID_PREFIXED) * 10 + 1;
      }

      case MeetingRoomType.BROADCAST: {
        const { contactId, programId } = meetingRoomConfig;
        if (meetingRoomConfig.broadcastType === BroadcastType.PROMO_CONTACT) {
          return (contactId % MAX_MEETING_ROOM_ID_PREFIXED) * 10 + 2;
        } else if (meetingRoomConfig.broadcastType === BroadcastType.PROGRAM_SPEAKER) {
          return (programId % MAX_MEETING_ROOM_ID_PREFIXED) * 10 + 2;
        } else {
          return null;
        }
      }

      default:
        return null;
    }
  }

  public static getLocalTrtcUserId(meetingRoomConfig: TMeetingRoomConfig): string {
    const { type } = meetingRoomConfig;
    switch (type) {
      case MeetingRoomType.MEETING: {
        const { eventId, meetingId, contactId } = meetingRoomConfig;
        return '' + eventId + '_' + meetingId + '_' + contactId;
      }

      case MeetingRoomType.BROADCAST: {
        const { eventId, contactId, programId } = meetingRoomConfig;
        if (meetingRoomConfig.broadcastType === BroadcastType.PROMO_CONTACT) {
          return '' + eventId + '_' + contactId;
        } else if (meetingRoomConfig.broadcastType === BroadcastType.PROGRAM_SPEAKER) {
          return '' + eventId + '_' + contactId + '_' + programId;
        } else {
          return null;
        }
      }

      default:
        return null;
    }
  }

  public static getBroadcastPushUrl(): string {
    return LVB_PUSH_URL;
  }

  public static getBroadcastPushKey(meetingRoomConfig: TMeetingRoomConfig, expire: number): string {
    const { type } = meetingRoomConfig;
    switch (type) {
      case MeetingRoomType.BROADCAST: {
        const txTime = ('' + parseInt('' + expire, 10).toString(16)).toUpperCase();
        const broadcastName = MeetingRoomsHelper.getBroadcastName(meetingRoomConfig);
        const txSecret = CryptoJS.MD5(LVB_PUSH_PROTECTION_KEY + broadcastName + txTime);
        return `${broadcastName}?txSecret=${txSecret}&txTime=${txTime}`;
      }

      default:
        return null;
    }
  }
}
