import { ActionContext, Module } from 'vuex';
import loginApi, {
  TConfirmCodeRequestParams,
  TConfirmCodeResponseData,
  TPasswordChangeConfirmRequestParams,
  TSendLoginRequestParams
} from '@/_api/login/login.api';
import ApiErrorResponseData from '@/_types/api/api-error-response-data.class';
import { TAuthStoreState } from '@/_types/store/auth-store-state.type';
import { TAppStoreState } from '@/_types/store/app-store-state.type';
import CookiesHelper from '@/_helpers/cookies-helper';
import { Location } from 'vue-router';

const tokenKey = 'user-token';
const initialToken = CookiesHelper.readCookie(tokenKey) || '';

loginApi.token = initialToken;

export enum AuthLoginStatus {
  NONE = '',
  LOADING = 'loading',
  SUCCESS = 'success',
  ERROR = 'error',
}

const authStore: Module<TAuthStoreState, TAppStoreState> = {
  namespaced: true,
  state: {
    confirmId: '',
    deviceId: (new Date()).getTime() + '_id',
    token: initialToken,
    status: AuthLoginStatus.NONE, // TODO: maybe, rename to smth like sendCredentialsRequestStatus?
    authError: null,
    forgotPasswordRequestStatus: AuthLoginStatus.NONE,
    forgotPasswordRequestError: null,
    changePasswordRequestStatus: AuthLoginStatus.NONE,
    changePasswordRequestError: null,
    isAuthPopupVisible: false,
    authPopupTargetRoute: null
  },
  getters: {
    isAuthenticated: (state: TAuthStoreState): boolean => !!state.token,
    isLoading: (state: TAuthStoreState): boolean => state.status === AuthLoginStatus.LOADING,
    authToken: (state: TAuthStoreState): string => state.token,
    isAuthPopupVisible: (state: TAuthStoreState): boolean => state.isAuthPopupVisible,
    authPopupTargetRoute: (state: TAuthStoreState): Location => state.authPopupTargetRoute,
  },
  mutations: {
    clearAuthError(state: TAuthStoreState): void {
      state.authError = null;
    },
    setConfirmId(state: TAuthStoreState, confirmId: string): void {
      state.confirmId = confirmId;
    },
    removeConfirmId(state: TAuthStoreState): void {
      state.confirmId = '';
    },
    authSuccess(state: TAuthStoreState, {token}: TConfirmCodeResponseData): void {

      CookiesHelper.createCookie({name: tokenKey, value: token, maxAge: 60 * 60 * 24 * 365});

      state.status = AuthLoginStatus.SUCCESS;
      state.token = token;
      state.authError = null;
    },
    authRequest(state: TAuthStoreState): void {
      state.status = AuthLoginStatus.LOADING;
    },
    authError(state: TAuthStoreState, errorData: ApiErrorResponseData): void {
      state.status = AuthLoginStatus.ERROR;
      state.authError = errorData;
    },
    authLogout(state: TAuthStoreState): void {

      CookiesHelper.deleteCookie(tokenKey);

      state.token = '';
      state.confirmId = '';
      state.status = AuthLoginStatus.NONE;
    },
    forgotPasswordRequest(state: TAuthStoreState): void {
      state.forgotPasswordRequestStatus = AuthLoginStatus.LOADING;
    },
    forgotPasswordRequestSuccess(state: TAuthStoreState): void {
      state.forgotPasswordRequestStatus = AuthLoginStatus.SUCCESS;
      state.forgotPasswordRequestError = null;
    },
    forgotPasswordRequestError(state: TAuthStoreState, errorData: ApiErrorResponseData): void {
      state.forgotPasswordRequestStatus = AuthLoginStatus.ERROR;
      state.forgotPasswordRequestError = errorData;
    },
    changePasswordRequest(state: TAuthStoreState): void {
      state.changePasswordRequestStatus = AuthLoginStatus.LOADING;
    },
    changePasswordRequestSuccess(state: TAuthStoreState): void {
      state.changePasswordRequestStatus = AuthLoginStatus.SUCCESS;
      state.changePasswordRequestError = null;
    },
    changePasswordRequestError(state: TAuthStoreState, errorData: ApiErrorResponseData): void {
      state.changePasswordRequestStatus = AuthLoginStatus.ERROR;
      state.changePasswordRequestError = errorData;
    },
    setAuthPopupVisible(state: TAuthStoreState, value: boolean): void {
      state.isAuthPopupVisible = value;
    },
    setAuthPopupTargetRoute(state: TAuthStoreState, value: Location): void {
      state.authPopupTargetRoute = value;
    },
  },
  actions: {

    /* Triggers the API Send login request */
    sendLogin: async ({commit}: ActionContext<TAuthStoreState, TAppStoreState>, payload: TSendLoginRequestParams): Promise<void> => {
      commit('authRequest');
      let response;
      try {
        response = await loginApi.loginReq(payload);
      } catch (error) {
        commit('authError', error.data);
        return;
      }
      commit('authSuccess', response);
    },

    /* Triggers the forgot password request */
    forgotPassword: async ({commit}: ActionContext<TAuthStoreState, TAppStoreState>, login: string): Promise<void> => {
      commit('forgotPasswordRequest');
      let response;
      try {
        response = await loginApi.forgotPasswordReq(login);
      } catch (error) {
        commit('forgotPasswordRequestError', error.data);
        return;
      }
      commit('forgotPasswordRequestSuccess', response);
      commit('setConfirmId', response.confirmation_id);
    },

    /* Triggers the change password request */
    changePassword: async ({commit}: ActionContext<TAuthStoreState, TAppStoreState>, payload: TPasswordChangeConfirmRequestParams): Promise<void> => {
      commit('changePasswordRequest');
      let response;
      try {
        response = await loginApi.changePasswordReq(payload);
      } catch (error) {
        commit('changePasswordRequestError', error.data);
        return;
      }
      commit('changePasswordRequestSuccess', response);
      commit('authSuccess', response);
    },

    sendPhoneNumber: async ({commit}: ActionContext<TAuthStoreState, TAppStoreState>, phone: string): Promise<void> => {
      commit('authRequest');
      let response;
      try {
        response = await loginApi.phoneReq(phone);
      } catch (error) {
        commit('authError', error.data);
        return;
      }
      commit('setConfirmId', response.confirmation_id);
    },

    sendConfirmCode: async ({commit}: ActionContext<TAuthStoreState, TAppStoreState>, data: TConfirmCodeRequestParams): Promise<void> => {
      commit('authRequest');
      let responseData: TConfirmCodeResponseData;
      try {
        responseData = await loginApi.confirmCode(data);
      } catch (error) {
        commit('authError', error.data);
        return;
      }
      commit('authSuccess', responseData);
    },
    authLogout: ({commit}: ActionContext<TAuthStoreState, TAppStoreState>): void => {
      commit('authLogout');
    },
    removeConfirmId: ({commit}: ActionContext<TAuthStoreState, TAppStoreState>): void => {
      commit('removeConfirmId');
    },
    toggleAuthPopupVisible: ({commit, state}: ActionContext<TAuthStoreState, TAppStoreState>): void => {
      commit('setAuthPopupVisible', !state.isAuthPopupVisible);
    },
    setAuthPopupVisible: ({commit}: ActionContext<TAuthStoreState, TAppStoreState>, payload: any): void => {
      const { isAuthPopupVisible, targetRoute, visibleScreen } = payload;
      commit('setAuthPopupVisible', isAuthPopupVisible);
      commit('setAuthPopupTargetRoute', targetRoute);
      if (visibleScreen ) {
        commit('setAuthPopupVisibleScreen', targetRoute);
      }
    },
    clearAuthPopupTargetRoute({commit}: ActionContext<TAuthStoreState, TAppStoreState>): void {
      commit('setAuthPopupTargetRoute', null);
    },
    clearAuthError({commit}: ActionContext<TAuthStoreState, TAppStoreState>): void {
      commit('clearAuthError');
    }
  },
};

export default authStore;
