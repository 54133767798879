



import Vue from 'vue';
import { mapGetters, mapState } from 'vuex';
import { TBadgeNotifications } from '@/_modules/notifications-popup/types/badgeNotifications.type';
import { TMessagesList } from '@/_types/messages-list.type';
import { TMessage } from '@/_types/messages.type';
import { TContact } from '@/_types/contact.type';
import iconClose from '@/_modules/icons/components/icon-close.vue';
import { TMeeting } from '@/_types/meeting/meeting.type';
import { TMeetingsList } from '@/_types/store/meetings-list.type';
import avatar from '@/_components/avatar/avatar.vue';
import _isEqual from 'lodash.isequal';
import { DateTimeFormat } from '@/_types/date-time-format.enum';
import { TUser } from '@/_types/user.type';
// import DateTimeHelper from '@/_helpers/date-time.helper';
// eslint-disable-next-line no-use-before-define,@typescript-eslint/no-var-requires
const notificationSound = require('@/sounds/intuition.ogg');

type TNotificationsPopupData = {
  messagePopups: TMessage[];
  meetingsPopups: TMeeting[];
  addIterationsMsgList: number;
  removeIterationsMsgList: number;
  addIterationsMtngsList: number;
  removeIterationsMtngsList: number;
  messagesList: TMessage[];
  meetingsList: TMeeting[];
  unreadNotifications: TBadgeNotifications;
  generateDelay: number;
  removeDelay: number;
  iterationsDelay: number;
  messageIds: number[];
  meetingsIds: number[];
}
type INotificationsPopupComputed = {
  notifications: TBadgeNotifications;
  messagesCount: number;
  meetingsCount: number;
  messages: TMessagesList;
  contact: TContact;
  currentUser: TUser;
  userScheduleForNotificationsPopup: TMeetingsList;
}

interface INotificationsPopupMethods {
  generateMessagesArray: () => void;
  removeLastMessage: () => void;
  generateMeetingsArray: () => void;
  removeLastMeeting: () => void;
  redirectToMessages: (contactId: string, messageId: number, event: ElementEventMap) => void;
  redirectToMeetings: (contactId: string, messageId: number, event: ElementEventMap) => void;
  closeMessagesPopup: (id: number) => void;
  closeMeetingsPopup: (id: number) => void;
  setAvatar: (img: string) => string;
  playSound: () => void;
  setMessageList: (list: TMessage[]) => void;
  setMeetingsList: (list: TMeeting[]) => void;
  time: (time: string) => string;
  fullDate: (date: string) => string;
}

const NotificationPopUp = Vue.extend<TNotificationsPopupData, INotificationsPopupMethods, INotificationsPopupComputed>({
  name: 'notificationsPopUp',
  components: { iconClose, avatar },
  computed: {
    ...mapState('meetingsStore', {
      userScheduleForNotificationsPopup: 'userScheduleForNotificationsPopup'
    }),
    ...mapGetters('_badgeNotificationsStore', {
      notifications: 'getNotifications',
      messagesCount: 'getMessagesCount',
      meetingsCount: 'getMeetingsCount',
    }),
    ...mapGetters('_messageStore', {
      messages: 'messages',
    }),
    ...mapGetters('promoPageStore', {
      contact: 'contact',
    }),
    ...mapGetters('_userStore', {
      currentUser: 'user',
    }),
  },
  watch: {
    messagesCount: {
      handler(newValue: number, oldValue: number): void {
        if (newValue > oldValue) {
          this.$store.dispatch('_messageStore/callMessageList', {
            eventId: Number(this.$route.params.eventId),
          });
        }
      }
    },
    meetingsCount: {
      handler(newValue: number): void {
        if (newValue && this.currentUser) {
          this.$store.dispatch('meetingsStore/getUserScheduleForNotificationsPopup', {
            event_id: Number(this.$route.params.eventId),
            user_id: this.currentUser.id
          });
        }
      }
    },
    userScheduleForNotificationsPopup: {
      handler(newValue: TMeetingsList, oldValue: TMeetingsList): void {
        this.meetingsList.length = 0;
        if (newValue && newValue.List && !oldValue) {
          this.setMeetingsList(newValue.List);
        } else if (newValue && oldValue) {
          if (!_isEqual(newValue.List, oldValue.List)) {
            this.setMeetingsList(newValue.List);
          }
        }
      }
    },
    messages: {
      deep: true,
      handler(newValue: TMessagesList, oldValue: TMessagesList): number | void {
        this.messagesList.length = 0;

        if (newValue && newValue.List && !oldValue) {
          this.setMessageList(newValue.List);
        } else if (newValue && oldValue) {
          if (!_isEqual(newValue.List, oldValue.List)) {
            this.setMessageList(newValue.List);
          }
        }
      }
    },
    messagesList: {
      handler(): void {
        this.generateMessagesArray();
      }
    },
    meetingsList: {
      handler(): void {
        this.generateMeetingsArray();
      }
    },
    addIterationsMsgList: {
      handler(newValue: number): void {
        if (newValue === 0) {
          this.removeIterationsMsgList = this.messagePopups.length;
          setTimeout(() => {
            this.removeLastMessage();
          }, this.iterationsDelay);

        }
      }
    },
    addIterationsMtngsList: {
      handler(newValue: number): void {
        if (newValue === 0) {
          this.removeIterationsMtngsList = this.meetingsPopups.length;
          setTimeout(() => {
            this.removeLastMeeting();
          }, this.iterationsDelay);

        }
      }
    }
  },
  data(): TNotificationsPopupData {
    return {
      messagesList: [],
      meetingsList: [],
      messagePopups: [],
      meetingsPopups: [],
      messageIds: [],
      meetingsIds: [],
      addIterationsMsgList: 0,
      removeIterationsMsgList: 0,
      addIterationsMtngsList: 0,
      removeIterationsMtngsList: 0,
      unreadNotifications: null,
      generateDelay: 500,
      removeDelay: 3000,
      iterationsDelay: 1000,
    };
  },
  methods: {
    setMessageList(list: TMessage[]): void {
      const messages: TMessage[] = list.map<TMessage>((item: TMessage): TMessage | any => {
        if (!item.is_mine && !item.is_read) {
          if (this.messageIds.length) {
            const isMatch = this.messageIds.find(id => { return id === item.id; });
            if (!isMatch) {
              return item;
            }
          } else {
            return item;
          }
        }
      }).filter((item: TMessage | void) => item);
      this.messagesList.push(...messages);
      this.addIterationsMsgList = this.messagesList.length;
      if (this.addIterationsMsgList) {
        this.playSound();
      }
      this.messagesList.forEach(item => {
        this.messageIds.push(item.id);
      });
    },
    setMeetingsList(list: TMeeting[]): void {
      // TODO: refactor, use only .filter
      const meetings: TMeeting[] = list.map<TMeeting>((item: TMeeting): TMeeting | any => {
        if (item.status === 'unconfirmed' && !item.is_creator && item.is_mine) {
          if (this.meetingsIds.length) {
            const isMatch = this.meetingsIds.find(id => {
              return id === item.id;
            });
            if (!isMatch) {
              return item;
            }
          } else {
            return item;
          }
        }
      }).filter((item: TMeeting | void) => item);

      this.meetingsList.push(...meetings);
      this.addIterationsMtngsList = this.meetingsList.length;

      if (this.addIterationsMtngsList) {
        this.playSound();
      }
      this.meetingsList.forEach(item => {
        this.meetingsIds.push(item.id);
      });
    },
    generateMessagesArray(): void {
      setTimeout(() => {
        if (this.addIterationsMsgList >= 0) {
          this.messagePopups.push(this.messagesList[this.addIterationsMsgList]);
          this.generateMessagesArray();
          this.addIterationsMsgList = this.addIterationsMsgList - 1;
        }
      }, this.generateDelay);

    },
    removeLastMessage(): void {
      setTimeout(() => {
        if (this.removeIterationsMsgList > 0) {
          this.messagePopups.pop();
          this.removeLastMessage();
          this.removeIterationsMsgList = this.removeIterationsMsgList - 1;
        }
      }, this.removeDelay);
    },
    generateMeetingsArray(): void {
      setTimeout(() => {
        if (this.addIterationsMtngsList >= 0) {
          this.meetingsPopups.push(this.meetingsList[this.addIterationsMtngsList]);
          this.generateMeetingsArray();
          this.addIterationsMtngsList = this.addIterationsMtngsList - 1;
        }
      }, this.generateDelay);
    },
    removeLastMeeting(): void {
      setTimeout(() => {
        if (this.removeIterationsMtngsList > 0) {
          this.meetingsPopups.pop();
          this.removeLastMeeting();
          this.removeIterationsMtngsList = this.removeIterationsMtngsList - 1;
        }
      }, this.removeDelay);
    },
    redirectToMessages(contactId: string, messageId: number): void {
      this.$store.dispatch('promoStore/setNavigationTab', 'messages');
      this.$router.push({ name: 'promo-page-contacts-contact', params: { contact_id: contactId } }).catch((e) => {
        throw e;
      });
      this.closeMessagesPopup(messageId);
    },
    redirectToMeetings(contactId: string, messageId: number): void {
      this.$store.dispatch('promoStore/setNavigationTab', 'meetings');
      this.$router.push({ name: 'promo-page-calendar', params: { contact_id: contactId } }).catch((e) => {
        throw e;
      });

      this.closeMeetingsPopup(messageId);
    },
    closeMessagesPopup(id: number): void {
      this.messagePopups.filter((item, index) => {
        if (item && item.id === id) {
          this.messagePopups.splice(index, 1);
        }
      });
    },
    closeMeetingsPopup(id: number): void {
      this.meetingsPopups.filter((item, index) => {
        if (item && item.id === id) {
          this.meetingsPopups.splice(index, 1);
        }
      });
    },
    setAvatar(img: string): string {
      if (!img) {
        return null;
      }
      return img;

    },
    playSound(): void {
      const audio = new Audio(notificationSound);
      audio.play();
    },
    time(time: string): string {
      if (time.indexOf('Z') > 0) {
        // return DateTimeHelper.messageTime(time, true); //TODO: need to enable DateTimeHelper
        return this.$moment(time).utc(true).format(DateTimeFormat.HH_MM);
      } else {
        // return DateTimeHelper.messageTime(time + 'Z', true); //TODO: need to enable DateTimeHelper
        const _time = time + 'Z';
        return this.$moment(_time).utc(true).format(DateTimeFormat.HH_MM);
      }

    },
    fullDate(date: string): string {
      if (!date) {
        return '';
      }
      return this.$moment(date).utc(false).format(DateTimeFormat.SHORT_DATE);
    }
  }
});
export default NotificationPopUp;
