<template>
  <div class="section navigation" ref="sectionNavigation">
    <div class="section-body">
      <div class="nav-layout flex">
        <div class="part">
          <router-link
            class="brand"
            :to="getHomeURL"
            v-if="isHeaderLogoVisible"
          >
            <img
              alt="Biletum"
              src="@/assets/images/events-wallet-logo.svg"
              width="136"
            />
            <span
              class="home-site-intro"
              v-if="isHomeSiteIntroVisible"
            >{{ $t('homeCards.headerIntro') }}</span>
          </router-link>
<!--          <router-link class="brand" to="/">-->
<!--            <img-->
<!--                v-if="($route.name !== 'event-info') && ($route.name && $route.name.indexOf('promo') < 0)"-->
<!--                alt="Biletum"-->
<!--                src="../../../assets/images/biletum-logo.svg"-->
<!--                width="136"/>-->
<!--            <span-->
<!--                v-else-->
<!--                class="service-by-brand"-->
<!--            >-->
<!--              {{ $t('navigation.serviceBy') }}-->
<!--           </span>-->
<!--          </router-link>-->
        </div>
        <div class="part">
          <div class="flex top-menu">
            <div class="event-links">
              <!--      <div>-->
              <!--        <router-link :to="{ name: 'contact-create' }">-->
              <!--          <span>{{ $t('navigation.contacts') }}</span>-->
              <!--        </router-link>-->
              <!--      </div>-->
              <!--              TODO: Removed temporarily from navigation: Service by biletum, All events, Create event-->
              <!--              <div class="inline-block" v-if="isAuthenticated && this.$route.name !== 'event-list'">-->
              <!--                <router-link :to="{ name: 'event-list' }">-->
              <!--                  <span>{{ $t('navigation.events') }}</span>-->
              <!--                </router-link>-->
              <!--              </div>-->
              <div class="inline-block" v-if="isAuthenticated && this.$route.name !== 'event-info' && this.$route.name !== 'event-create'">
                <button class="control control-button create-event-btn" @click="createEvent()">
                  {{ $t('navigation.createEvent') }}
                </button>
              </div>
            </div>
            <div class="top-languages">
              <language-picker></language-picker>
            </div>
<!--            <div v-if="isAuthenticated" TODO: return when user avatar is needed-->
<!--                 class="user-avatar">-->
<!--            </div>-->
            <div v-if="isAuthenticated" @click="logout"
                 class="logout">
            </div>
<!--            <div v-if="!isAuthenticated && !isAuthLoading">-->
<!--              <router-link :to="authRoute">{{ $t('title.login') }}</router-link>-->
<!--            </div>-->

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';
import LanguagePicker from '@/_components/language-picker/language-picker';

const Navigation = Vue.extend({
  name: 'navigation',
  components: { LanguagePicker },
  computed: {
    ...mapGetters({
      isAuthenticated: 'authStore/isAuthenticated',
      isAuthLoading: 'authStore/isLoading',
    }),
    // TODO: refactoring
    // eslint-disable-next-line @typescript-eslint/typedef,@typescript-eslint/explicit-function-return-type
    authRoute() {
      if (this.$route.name === 'auth-page') {
        return { name: 'auth-page' };
      } else {
        return {
          name: 'auth-page',
          params: { redirect: this.$route.path },
        };
      }
    },
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    isHeaderLogoVisible() {
      return this.$route.meta ? !!this.$route.meta.showHeaderLogo : false;
    },
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    isHomeSiteIntroVisible() {
      return this.$route.meta ? !!this.$route.meta.showHomeSiteIntro : false;
    },

    /* Home URL is / + current locale */
    // eslint-disable-next-line @typescript-eslint/typedef,@typescript-eslint/explicit-function-return-type
    getHomeURL() {
      return '/' + (this.$i18n.locale === this.$i18n.fallbackLocale ? '' : this.$i18n.locale);
    },
  },
  data() {
    return {
      userAvatar: '/img/defaultAvatar.png',
    };
  },
  methods: {
    // TODO: refactoring
    // eslint-disable-next-line @typescript-eslint/typedef,@typescript-eslint/explicit-function-return-type
    async logout() {
      await this.$store.dispatch('authStore/authLogout');
      this.$router.push({ name: 'auth-page', params: { forceAuthPageRoute: true } });
    },
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    createEvent() {
      this.$router.push({ name: 'event-create' });
    }
  },
});

export default Navigation;
</script>

<style lang="scss" scoped>
  .navigation {
    position: sticky;
    z-index: $z-index-navigation;
    width: 100%;
    top: 0;
    left: 0;
    color: #000;
    background-color: #fff;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.29);

    &.section-mainnav-event {
      height: $navigation-height;
    }

    .nav-layout {
      justify-content: space-between;
      align-items: center;
    }

    .top-menu {
      font-family: Point, "Point Light", sans-serif;
      font-weight: 300;
      font-size: 1.8rem;
      align-items: center;

      & > div,
      .event-links > div {
        margin: 0 10px 0 45px;
      }

      & > div.top-languages {
        @media (max-width: 640px) {
          margin-left: 0;
        }
      }

      .event-links {
        a {
          color: #000;
        }

        a.router-link-exact-active {
          color: $palette-aqua;
          font-weight: 500;
          font-family: Point, "Point Regular", sans-serif;
        }
      }
    }

    .user-avatar {
      overflow: hidden;
      border-radius: 200px;
      width: 45px;
      height: 45px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      background-image: url("~@/assets/images/icon-noavatar.svg");
    }

  }

  .brand {
    display: flex;
    align-items: center;
  }

  .logout {
    opacity: 0;
    width: 27px;
    height: 25px;
    margin-left: 16px !important;
    background-image: url("~@/assets/images/icon-logout-black.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;

    &:hover {
      cursor: pointer;
    }
  }

  /* Стили для фиксированной книзу шапки, которая выводится на карточке евента */
  .section-mainnav-event {
    background-color: $biletum-light-blue;
    background-image: linear-gradient(94deg, #38a1ff 0%, #38a1ff 50%, #00f8ff 100%);
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.16);
    color: #fff;
    position: fixed;
    top: auto;
    bottom: 0;

    .top-menu .event-links {
      display: none !important;
    }

    .top-menu .event-links > div {
      margin: 0 0 0 9px;
    }

    .section-body {
      padding-top: 9px;
      padding-bottom: 9px;
    }

    .top-menu .event-links,
    .top-menu .event-links a,
    .brand .service-by-brand {
      color: #fff !important;
      font-size: 1.1rem;
    }

    .user-avatar {
      width: 24px;
      height: 24px;
    }

    .logout {
      width: 14px;
      height: 24px;
      margin-left: 16px !important;
      background-image: url("~@/assets/images/icon-logout.svg");
    }
  }

  .home-site-intro {
    font-family: Point, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 2rem;
    line-height: 2.4rem;
    margin-left: 4.9rem;
    color: #000;

    @media (max-width: 640px) {
      display: none;
    }
  }

  .create-event-btn {
    background: #00b6f8;
    border-radius: 33px;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0;
    color: #fff;
    height: 36px;
    min-width: 190px;
    padding: 0 30px;
    margin: 0 auto;
  }
</style>
