import api from "../../api";

export default {
  strict: true,
  namespaced: true,
  state: {
    id: localStorage.getItem('eventId'),
    editContactLoading: false,
    editContactSuccess: false,
    contactList: {
      Limit: null,
      Offset: null,
      Total: null,
      List: {
        event_id: null,
        id: null,
        is_favorite: false,
        unread_messages: 0,
        name: "",
        surname: "",
        photo_url: "",
        company_name: "",
        company_website: "",
        company_position: "",
        company_description: "",
        user: {
          birthday: "",
          city: "",
          companies: null,
          country: "",
          email: "",
          facebook: "",
          first_name: "",
          id: null,
          instagram: "",
          last_name: "",
          middle_name: "",
          organization: null,
          phone: "",
          photo_url: "",
          position: null,
          skype: "",
          telegram: "",
          twitter: "",
          viber: "",
          wechat: "",
        }
      }
    },
    contact: {
      event_id: null,
      id: null,
      is_favorite: false,
      unread_messages: 0,
      name: "",
      surname: "",
      email: "",
      company_name: "",
      company_website: "",
      company_position: "",
      company_description: "",
      video_stream_url: "",
      country: "",
      city: "",
      files: [],
      photo_url: "",
      user: {
        birthday: "",
        city: "",
        companies: null,
        country: "",
        email: "",
        facebook: "",
        first_name: "",
        id: null,
        instagram: "",
        last_name: "",
        middle_name: "",
        organization: null,
        phone: "",
        photo_url: "",
        position: null,
        skype: "",
        telegram: "",
        twitter: "",
        viber: "",
        wechat: "",
      }
    },
    contactError: '',
    contactListLoading: false,
    contactInfoRequested: false,
  },
  getters: {
    contactInfo: (state) => state.contact,
    contactInfoRequested: (state) => state.contactInfoRequested,
    editContactSuccess: (state) => state.editContactSuccess,
  },
  mutations: {
    setContactInfoRequested(state, data){
      state.contactInfoRequested = data;
    },
    setEditContactLoading(state, data){
      state.editContactLoading = data;
    },
    setEditContactSuccess(state, data){
      state.editContactSuccess = data;
    },
    addContact(state, data) {
      state.contact = {
        event_id: data.event_id,
        id: data.id,
        is_favorite: data.is_favorite,
        unread_messages: data.unread_messages,
        name: data.name,
        surname: data.surname,
        note: data.note,
        video_stream_url: data.video_stream_url,
        photo_url: data.photo_url,
        files: data.files,
        country: data.country,
        city: data.city,
        company_name: data.company_name,
        company_website: data.company_website,
        company_position: data.company_position,
        company_description: data.company_description,
        user: {
          birthday: data.user.birthday,
          city: data.user.city,
          companies: data.user.companies,
          country: data.user.country,
          email: data.user.email,
          facebook: data.user.facebook,
          first_name: data.user.first_name,
          files: data.user.files,
          id: data.user.id,
          instagram: data.user.instagram,
          last_name: data.user.last_name,
          middle_name: data.user.middle_name,
          organization: data.user.organization,
          phone: data.user.phone,
          photo_url: data.user.photo_url,
          position: data.user.position,
          skype: data.user.skype,
          telegram: data.user.telegram,
          twitter: data.user.twitter,
          viber: data.user.viber,
          wechat: data.user.wechat,
        },
      }
    },
    setContactList(state, data) {
      let list = data.List.map((item) => {
        return {
          event_id: item.event_id,
          id: item.id,
          is_favorite: item.is_favorite,
          unread_messages: item.unread_messages,
          name: item.name,
          surname: item.surname,
          note: item.note,
          video_stream_url: item.video_stream_url,
          photo_url: item.photo_url,
          country: item.country,
          city: item.city,
          company_name: item.company_name,
          company_website: item.company_website,
          company_position: item.company_position,
          company_description: item.company_description,
          files: item.files,
          user: {
            birthday: item.user.birthday,
            city: item.user.city,
            companies: item.user.companies,
            country: item.user.country,
            email: item.user.email,
            facebook: item.user.facebook,
            first_name: item.user.first_name,
            files: item.user.files,
            id: item.user.id,
            instagram: item.user.instagram,
            last_name: item.user.last_name,
            middle_name: item.user.middle_name,
            organization: item.user.organization,
            phone: item.user.phone,
            photo_url: item.user.photo_url,
            position: item.user.position,
            skype: item.user.skype,
            telegram: item.user.telegram,
            twitter: item.user.twitter,
            viber: item.user.viber,
            wechat: item.user.wechat,
          },
        }
      });

      state.contactList = {
        Limit: data.Limit,
        Offset: data.Offset,
        Total: data.Total,
        List: list,
      };
    },
    setContact(state, data) {
      state.contact = {
        event_id: data.event_id,
        id: data.id,
        is_favorite: data.is_favorite,
        unread_messages: data.unread_messages,
        name: data.name,
        surname: data.surname,
        note: data.note,
        video_stream_url: data.video_stream_url,
        email: data.email,
        country: data.country,
        city: data.city,
        company_name: data.company_name,
        company_website: data.company_website,
        company_position: data.company_position,
        company_description: data.company_description,
        photo_url: data.photo_url,
        files: data.files,
        user: {
          birthday: data.user.birthday,
          city: data.user.city,
          companies: data.user.companies,
          country: data.user.country,
          email: data.user.email,
          facebook: data.user.facebook,
          first_name: data.user.first_name,
          id: data.user.id,
          instagram: data.user.instagram,
          last_name: data.user.last_name,
          middle_name: data.user.middle_name,
          organization: data.user.organization,
          phone: data.user.phone,
          photo_url: data.user.photo_url,
          position: data.user.position,
          skype: data.user.skype,
          telegram: data.user.telegram,
          twitter: data.user.twitter,
          viber: data.user.viber,
          wechat: data.user.wechat,
        },
      }
    },
    updateContactAfterEdit(state, updateData) {
      const contactUpdate = {};
      for (const key of Object.keys(updateData)) {
        if (typeof state.contact[key] !== 'undefined') {
          contactUpdate[key] = updateData[key];
        }
      }
      state.contact = Object.assign({}, state.contact, contactUpdate);
    },
    contactError(state, data) {
      state.createContactError = data
    },
    setEventId(state, data) {
      localStorage.setItem('eventId', data);
      state.id = localStorage.getItem('eventId')
    },
    setContactPhotoURL(state, data){
      state.contact.photo_url = data;
    },
    setContactListLoading(state, data){
      state.contactListLoading = data;
    }
  },
  actions: {
    addContact: async function ({commit}, data) {
      await api.contacts.addContact(data)
        .then((resolve) => {
          commit('addContact', resolve);
        }).catch((e) => {
          commit('contactError', e.data);
        });
    },
    addContactByCode: async function ({commit}, data) {
      await api.contacts.addContactByCode(data)
        .then((resolve) => {
          commit('addContact', resolve);
        }).catch((e) => {
          commit('contactError', e.data);
        });
    },
    editContact: async function ({commit}, data) {
      commit('setEditContactLoading', true);
      commit('setEditContactSuccess', false);
      await api.contacts.editContact(data)
        .then((resolve) => {
          commit('setEditContactLoading', false);
          commit('setEditContactSuccess', true);
          commit('updateContactAfterEdit', data);
          if(resolve && resolve.statusText === 'Accepted'){
            commit('setEditContactSuccess', true);
          }
          // commit('addContact', resolve);
        }).catch((e) => {
          commit('setEditContactLoading', false);
          commit('setEditContactSuccess', false);
          commit('contactError', e.data);
        });
    },
    removeContact: async function ({commit}, data) {
      await api.contacts.removeContact(data)
        .then(() => {
        }).catch((e) => {
          commit('noteError', e.data);
        });
    },
    addFavContact: async function ({commit}, data) {
      await api.contacts.addFavContact(data)
        .then((resolve) => {
          // commit('addContact', resolve);
        }).catch((e) => {
          commit('contactError', e.data);
        });
    },
    removeFavContact: async function ({commit}, data) {
      await api.contacts.removeFavContact(data)
        .then(() => {
        }).catch((e) => {
          commit('contactError', e.data);
        });
    },
    callContactListMy: async function ({commit}, data) {
      commit('setContactListLoading', true);
      await api.contacts.contactList(data, 'own')
        .then((resolve) => {
          commit('setContactListLoading', false);
          commit('setContactList', resolve);
        }).catch((e) => {
          commit('setContactListLoading', false);
          commit('contactError', e.data);
        });
    },
    callContactListAll: async function ({commit}, data) {
      commit('setContactListLoading', true);
      await api.contacts.contactList(data, 'all')
        .then((resolve) => {
          commit('setContactListLoading', false);
          commit('setContactList', resolve);
        }).catch((e) => {
          commit('setContactListLoading', false);
          commit('contactError', e.data);
        });
    },
    callContactListByMessages: async function ({commit}, data) {
      // AW-551
      commit('setContactListLoading', true);
      await api.contacts.contactListByMessages(data)
        .then((resolve) => {
          commit('setContactListLoading', false);
          // Messages list is message-centric,
          // making it contact-centric...
          if(resolve && resolve.List){
            let contactIds = {};
            resolve.List = resolve.List.map((x) => x.contact);
            // ...and removing duplicate contacts
            resolve.List = resolve.List.filter((x)=>{
              if( Object.prototype.hasOwnProperty.call(contactIds, x.id) === false ){
                contactIds[x.id] = 1;
              } else {
                contactIds[x.id]++;
              }
              return contactIds[x.id] === 1;
            });
          }
          commit('setContactList', resolve);
        }).catch((e) => {
          commit('setContactListLoading', false);
          commit('contactError', e.data);
        });
    },
    callContact: async function ({commit}, data) {
      await api.contacts.contact(data)
        .then((resolve) => {
          commit('setContact', resolve);
          commit('setContactInfoRequested', true);
        }).catch((e) => {
          commit('contactError', e.data);
          commit('setContactInfoRequested', true);
        });
    },
    setEventId({commit, rootState}) {
      if (rootState.eventStore.eventInfo.id) {
        commit('setEventId', rootState.eventStore.eventInfo.id);
      }
    },
    addMyContactToEvent: async function ({commit}, data) {
      await api.contacts.addMyContactToEvent(data)
        .then((resolve) => {
          // commit('addContact', resolve);
        }).catch((e) => {
          commit('contactError', e.data);
        });
    },
    putContactFile: async function ({commit}, payload) {
      await api.contacts.putContactFile({url: payload.url, filename: payload.filename}, payload.event_id)
        .then((resolve) => {
          // commit('setPromoPageList', resolve);
        }).catch((e) => {
          commit('contactError', e.data);
        });
    },
    deleteContactFile: async function ({commit}, payload) {
      await api.contacts.deleteContactFile(payload.file_id, payload.event_id)
        .then((resolve) => {
          // commit('setPromoPageList', resolve);
        }).catch((e) => {
          commit('contactError', e.data);
        });
    },

    // createContact(data) {
    // 	return {
    // 		event_id: data.event_id,
    // 		id: data.id,
    // 		is_favorite: data.is_favorite,
    // 		name: data.name,
    // 		user: {
    // 			birthday: data.user.birthday,
    // 			city: data.user.city,
    // 			companies: data.user.companies,
    // 			country: data.user.country,
    // 			email: data.user.email,
    // 			facebook: data.user.facebook,
    // 			first_name: data.user.first_name,
    // 			id: data.user.id,
    // 			instagram: data.user.instagram,
    // 			last_name: data.user.last_name,
    // 			middle_name: data.user.middle_name,
    // 			organization: data.user.organization,
    // 			phone: data.user.phone,
    // 			photo_url: data.user.photo_url,
    // 			position: data.user.position,
    // 			skype: data.user.skype,
    // 			telegram: data.user.telegram,
    // 			twitter: data.user.twitter,
    // 			viber: data.user.viber,
    // 			wechat: data.user.wechat,
    // 		},
    // 	}
    // }
  }
}

