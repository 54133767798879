import moment from 'moment';
import UrlHelper from '@/_helpers/url.helper';
import { TEvent } from '@/_types/event.type';
import { TimeStatus } from '@/_types/time-status.enum';
import { TContact } from '@/_types/contact.type';

export default class EventHelper {

  public static redirectToEventDomain(eventInfo: TEvent): boolean {

    let result = false;

    if (eventInfo.event_domain) {
      const url = eventInfo.event_domain;
      if (!UrlHelper.isSameDomain(url)) {
        // TODO: account for port. When window.location.port is not empty, changing location.host redirects to URL with port, which is bad
        (window as any).location.host = url;
        result = true;
      }
    }

    return result;
  }

  public static getEventTimeStatus(event: TEvent): TimeStatus {
    if (!event || (!event.date_start && !event.date_end)) {
      return TimeStatus.UNKNOWN;
    }

    const now = moment();
    const start = event.date_start ? moment(event.date_start) : null;
    const end = event.date_end ? moment(event.date_end) : null;

    if (start && end) {
      if (now.isBefore(start, 'day')) {
        return TimeStatus.FUTURE;
      } else if (now.isAfter(end, 'day')) {
        return TimeStatus.PAST;
      } else {
        return TimeStatus.PRESENT;
      }
    } else if (start) {
      if (now.isBefore(start, 'day')) {
        return TimeStatus.FUTURE;
      } else {
        return TimeStatus.PRESENT;
      }
    } else if (end) {
      if (now.isAfter(end, 'day')) {
        return TimeStatus.PAST;
      } else {
        return TimeStatus.PRESENT;
      }
    }

    return TimeStatus.UNKNOWN;
  }

  public static getEventExpireTimestamp(event: TEvent): number {
    return (event && event.date_end) ? moment(event.date_end).unix() : 0;
  }

  public static isContactEventOrganizer(event: TEvent, contact: TContact): boolean {
    if (!event || !event.creator_user_id || !contact || !contact.user) {
      return false;
    }
    return event.creator_user_id === contact.user.id;
  }
}
