import { ActionContext, Module } from 'vuex';
import { TAppStoreState } from '@/_types/store/app-store-state.type';
import { TPromoProgramStoreState } from '@/_modules/promo/types/promo-program-store-state.type';
import AxiosCancellableRequest from '@/_types/api/axios-cancellable-request.class';
import promoProgramApi, { TGetConferenceRoomsParams } from '@/_modules/promo-program/api/promo-program.api';
import { TConferenceRoom } from '@/_modules/promo/types/conference-room.type';
import ContactHelper from '@/_helpers/contact.helper';
import { TConferenceProgram } from '@/_modules/promo/types/conference-program.type';
import PromoProgramHelper from '@/_modules/promo-program/helpers/promo-program-helper';
import { BroadcastType } from '@/_types/broadcasts/broadcast-type.enum';
import { TBroadcast } from '@/_types/broadcasts/broadcast.type';
import broadcastsService from '@/_services/broadcasts.service';
import { TMediaItem } from '@/_modules/events/types/media-item.type';

const getConferenceRoomsRequest = new AxiosCancellableRequest<TGetConferenceRoomsParams, TConferenceRoom[]>(promoProgramApi.getConferenceRooms.bind(promoProgramApi));

const promoProgramStore: Module<TPromoProgramStoreState, TAppStoreState> = {
  namespaced: true,
  state: {
    eventId: null,
    isLoading: false,
    conferenceRooms: null,
    lastError: null,
    unpinnedMediaItemsByProgramId: {},
  },
  getters: {

    isLoading: (state: TPromoProgramStoreState): boolean => {
      return state.isLoading;
    },

    lastError: (state: TPromoProgramStoreState): Error => {
      return state.lastError;
    },

    conferenceRooms: (state: TPromoProgramStoreState): TConferenceRoom[] => {
      return state.conferenceRooms;
    },

    getProgramById: (state: TPromoProgramStoreState) => (programId: number): TConferenceProgram => {
      let program: TConferenceProgram = null;
      for (let i = 0; i < state.conferenceRooms.length; i++) {
        if (!state.conferenceRooms[i].programs || !state.conferenceRooms[i].programs.length) {
          continue;
        }
        for (let j = 0; j < state.conferenceRooms[i].programs.length; j++) {
          if (state.conferenceRooms[i].programs[j].id === programId) {
            program = state.conferenceRooms[i].programs[j];
            break;
          }
        }
        if (program) {
          break;
        }
      }
      return program;
    },

    unpinnedMediaItems: (state: TPromoProgramStoreState): TMediaItem[] => {
      let items: TMediaItem[] = [];
      Object.entries(state.unpinnedMediaItemsByProgramId).forEach(unpinnedMediaItems => {
        items = [ ...items, ...unpinnedMediaItems[1] ];
      });
      return items;
    },

  },
  actions: {

    reset: ({ commit }: ActionContext<TPromoProgramStoreState, TAppStoreState>): void => {
      commit('setEventId', null);
    },

    loadProgram: async (context: ActionContext<TPromoProgramStoreState, TAppStoreState>, eventId: number): Promise<TConferenceRoom[]> => {
      const { commit, state } = context;
      commit('setEventId', eventId);

      if (state.conferenceRooms) {
        return state.conferenceRooms;
      }

      if (state.isLoading) {
        try {
          return await getConferenceRoomsRequest.promise;
        } catch (error) {
          return null;
        }
      }

      commit('getConferenceRoomsRequest');

      let conferenceRooms;
      try {
        conferenceRooms = await getConferenceRoomsRequest.load({ eventId });
        return conferenceRooms;
      } catch (error) {
        commit('setLastError', error);
        return null;
      } finally {
        commit('setConferenceRooms', conferenceRooms);
      }
    },

    createConferenceRoom: async (context: ActionContext<TPromoProgramStoreState, TAppStoreState>, conferenceRoom: TConferenceRoom): Promise<TConferenceRoom> => {
      const { commit } = context;
      let newConferenceRoom = null;
      commit('setLastError', null);
      try {
        newConferenceRoom = await promoProgramApi.createConferenceRoom(conferenceRoom);
      } catch (error) {
        commit('setLastError', error);
        return null;
      }

      commit('conferenceRoomCreated', newConferenceRoom);

      return newConferenceRoom;
    },

    updateConferenceRoom: async (context: ActionContext<TPromoProgramStoreState, TAppStoreState>, conferenceRoom: TConferenceRoom): Promise<void> => {
      const { commit } = context;
      commit('setLastError', null);
      try {
        await promoProgramApi.patchConferenceRoom({
          id: conferenceRoom.id,
          event_id: conferenceRoom.event_id,
          title: conferenceRoom.title,
        });
      } catch (error) {
        commit('setLastError', error);
        return null;
      }

      commit('conferenceRoomUpdated', conferenceRoom);
    },

    removeConferenceRoom: async (context: ActionContext<TPromoProgramStoreState, TAppStoreState>, { eventId, conferenceRoomId }): Promise<void> => {
      const { commit } = context;
      commit('setLastError', null);
      commit('setIsLoading', true);
      try {
        await promoProgramApi.removeConferenceRoom({
          event_id: eventId,
          conference_id: conferenceRoomId
        });
        commit('conferenceRoomRemoved', conferenceRoomId);
      } catch (error) {
        commit('setLastError', error);
      } finally {
        commit('setIsLoading', false);
      }
    },

    removeConferenceProgram: async (context: ActionContext<TPromoProgramStoreState, TAppStoreState>, { eventId, conferenceRoomId, programId }): Promise<void> => {
      const { commit } = context;
      commit('setLastError', null);
      commit('setIsLoading', true);
      try {
        await promoProgramApi.removeConferenceProgram({
          event_id: eventId,
          conference_id: conferenceRoomId,
          program_id: programId,
        });
        commit('conferenceProgramRemoved', { conferenceRoomId, programId });
      } catch (error) {
        commit('setLastError', error);
      } finally {
        commit('setIsLoading', false);
      }
    },

    toggleFavorite: async (context: ActionContext<TPromoProgramStoreState, TAppStoreState>, params: {
      eventId: number;
      conferenceRoomId: number;
      programId: number;
      isFavorite: boolean;
    }): Promise<void> => {
      const { commit } = context;
      commit('setProgramIsFavorite', { programId: params.programId, isFavorite: params.isFavorite });
      commit('setLastError', null);
      try {
        await promoProgramApi.setProgramFavorite({
          event_id: params.eventId,
          conference_id: params.conferenceRoomId,
          program_id: params.programId,
          is_favorite: params.isFavorite,
        });
      } catch (error) {
        commit('setProgramIsFavorite', { programId: params.programId, isFavorite: !params.isFavorite });
        commit('setLastError', error);
        return null;
      }
    },

    applyActiveBroadcasts: ({ commit }: ActionContext<TPromoProgramStoreState, TAppStoreState>, allBroadcasts: TBroadcast[]): void => {
      commit('applyActiveBroadcasts', allBroadcasts);
    },

    videoStreamEmbedUpdated: ({ commit }: ActionContext<TPromoProgramStoreState, TAppStoreState>, params: { programId: number; video_stream_embed: string }): void => {
      commit('videoStreamEmbedUpdated', params);
    },

    unpinMediaItem({ commit }: ActionContext<TPromoProgramStoreState, TAppStoreState>, params: { programId: number; id: string }): void {
      commit('unpinMediaItem', params);
    },

    pinMediaItem({ commit }: ActionContext<TPromoProgramStoreState, TAppStoreState>, params: { programId: number; id: string }): void {
      commit('pinMediaItem', params);
    },

    setActiveMediaItem({ commit }: ActionContext<TPromoProgramStoreState, TAppStoreState>, params: { programId: number; id: string }): void {
      commit('setActiveMediaItem', params);
    },

    setMediaItemIsPaused({ commit }: ActionContext<TPromoProgramStoreState, TAppStoreState>, params: { programId: number; id: string; isPaused: boolean }): void {
      commit('setMediaItemIsPaused', params);
    },

    setAllVideoMediaItemsIsPaused({ commit }: ActionContext<TPromoProgramStoreState, TAppStoreState>, params: { programId: number }): void {
      commit('setAllVideoMediaItemsIsPaused', params);
    },

  },
  mutations: {

    setEventId(state: TPromoProgramStoreState, eventId: number): void {
      eventId = eventId || null;

      if (state.eventId === eventId) {
        return;
      }

      getConferenceRoomsRequest.cancel();

      state.eventId = eventId || null;
      state.isLoading = false;
      state.conferenceRooms = null;
    },

    getConferenceRoomsRequest(state: TPromoProgramStoreState): void {
      state.isLoading = true;
      state.conferenceRooms = null;
    },

    setIsLoading(state: TPromoProgramStoreState, isLoading: boolean): void {
      state.isLoading = isLoading;
    },

    setLastError(state: TPromoProgramStoreState, error: Error): void {
      state.lastError = error;
    },

    setConferenceRooms(state: TPromoProgramStoreState, conferenceRooms: TConferenceRoom[]): void {

      if (conferenceRooms) {

        const allBroadcasts = broadcastsService.getActiveBroadcasts();
        const allProgramBroadcasts = allBroadcasts.filter(broadcast => broadcast.type === BroadcastType.PROGRAM_SPEAKER);
        const broadcastsByProgramId: { [programId: string]: TBroadcast[] } = {};
        allProgramBroadcasts.forEach(broadcast => {
          if (!broadcast.details.program || !broadcast.details.program.id) {
            return;
          }
          const programId = broadcast.details.program.id;
          if (!broadcastsByProgramId[programId]) {
            broadcastsByProgramId[programId] = [];
          }
          broadcastsByProgramId[programId].push(broadcast);
        });

        conferenceRooms.forEach(conferenceRoom => {
          if (conferenceRoom.programs) {
            conferenceRoom.programs.forEach(program => {
              PromoProgramHelper.updateProgramMediaItems(program, broadcastsByProgramId);
              if (program.speakers) {
                program.speakers.forEach(speaker => {
                  speaker.fullName = ContactHelper.getFullName(speaker);
                  speaker.fullCompany = ContactHelper.getFullCompany(speaker);
                });
              }
            });
          }
        });
      }

      state.conferenceRooms = conferenceRooms || null;
      state.isLoading = false;
    },

    conferenceRoomCreated(state: TPromoProgramStoreState, conferenceRoom: TConferenceRoom): void {
      if (state.conferenceRooms === null) {
        state.conferenceRooms = [];
      }

      if (conferenceRoom.programs) {
        conferenceRoom.programs.forEach(program => {
          PromoProgramHelper.updateProgramMediaItems(program, {});
          if (program.speakers) {
            program.speakers.forEach(speaker => {
              speaker.fullName = ContactHelper.getFullName(speaker);
              speaker.fullCompany = ContactHelper.getFullCompany(speaker);
            });
          }
        });
      }

      state.conferenceRooms.push(conferenceRoom);
    },

    conferenceRoomUpdated(state: TPromoProgramStoreState, conferenceRoom: TConferenceRoom): void {
      if (state.conferenceRooms === null) {
        state.conferenceRooms = [];
      }

      const existingConferenceRoom = state.conferenceRooms.find(item => item.id === conferenceRoom.id);
      if (!existingConferenceRoom) {
        return;
      }

      existingConferenceRoom.title = conferenceRoom.title;
    },

    conferenceRoomRemoved(state: TPromoProgramStoreState, conferenceRoomId: number): void {
      for (let i = 0; i < state.conferenceRooms.length; i++) {
        if (state.conferenceRooms[i].id === conferenceRoomId) {
          state.conferenceRooms.splice(i, 1);
          break;
        }
      }
    },

    conferenceProgramRemoved(state: TPromoProgramStoreState, { conferenceRoomId, programId }: { conferenceRoomId: number; programId: number }): void {
      for (let i = 0; i < state.conferenceRooms.length; i++) {
        if (state.conferenceRooms[i].id === conferenceRoomId) {
          for (let j = 0; j < state.conferenceRooms[i].programs.length; j++) {
            if (state.conferenceRooms[i].programs[j].id === programId) {
              state.conferenceRooms[i].programs.splice(j, 1);
              break;
            }
          }
          break;
        }
      }
    },

    applyActiveBroadcasts(state: TPromoProgramStoreState, allBroadcasts: TBroadcast[]): void {
      if (!state.conferenceRooms || !state.conferenceRooms.length) {
        return;
      }

      const allProgramBroadcasts = allBroadcasts.filter(broadcast => broadcast.type === BroadcastType.PROGRAM_SPEAKER);
      const broadcastsByProgramId: { [programId: string]: TBroadcast[] } = {};
      allProgramBroadcasts.forEach(broadcast => {
        if (!broadcast.details.program || !broadcast.details.program.id) {
          return;
        }
        const programId = broadcast.details.program.id;
        if (!broadcastsByProgramId[programId]) {
          broadcastsByProgramId[programId] = [];
        }
        broadcastsByProgramId[programId].push(broadcast);
      });

      for (let i = 0; i < state.conferenceRooms.length; i++) {
        if (!state.conferenceRooms[i].programs || !state.conferenceRooms[i].programs.length) {
          continue;
        }
        for (let j = 0; j < state.conferenceRooms[i].programs.length; j++) {
          const program = state.conferenceRooms[i].programs[j];
          PromoProgramHelper.updateProgramMediaItems(program, broadcastsByProgramId);
        }
      }
    },

    videoStreamEmbedUpdated(state: TPromoProgramStoreState, params: { programId: number; video_stream_embed: string }): void {
      if (!state.conferenceRooms || !state.conferenceRooms.length) {
        return;
      }
      for (let i = 0; i < state.conferenceRooms.length; i++) {
        if (!state.conferenceRooms[i].programs || !state.conferenceRooms[i].programs.length) {
          continue;
        }
        for (let j = 0; j < state.conferenceRooms[i].programs.length; j++) {
          const program = state.conferenceRooms[i].programs[j];
          if (program.id === params.programId) {
            program.video_stream_embed = params.video_stream_embed;
            break;
          }
        }
      }
    },

    setActiveMediaItem(state: TPromoProgramStoreState, params: { programId: number; id: string }): void {
      if (!state.conferenceRooms || !state.conferenceRooms.length) {
        return;
      }
      for (let i = 0; i < state.conferenceRooms.length; i++) {
        if (!state.conferenceRooms[i].programs || !state.conferenceRooms[i].programs.length) {
          continue;
        }
        for (let j = 0; j < state.conferenceRooms[i].programs.length; j++) {
          const program = state.conferenceRooms[i].programs[j];
          if (program.id === params.programId) {
            let foundMediaItem: TMediaItem = null;
            for (let k = 0; k < program.mediaItems.length; k++) {
              if (program.mediaItems[k].id === params.id) {
                foundMediaItem = program.mediaItems[k];
                program.mediaItems[k].isActive = true;
              } else {
                program.mediaItems[k].isActive = false;
              }
            }
            program.activeMediaItem = foundMediaItem;
            return;
          }
        }
      }
    },

    setMediaItemIsPaused(state: TPromoProgramStoreState, params: { programId: number; id: string; isPaused: boolean }): void {
      if (!state.conferenceRooms || !state.conferenceRooms.length) {
        return;
      }
      for (let i = 0; i < state.conferenceRooms.length; i++) {
        if (!state.conferenceRooms[i].programs || !state.conferenceRooms[i].programs.length) {
          continue;
        }
        for (let j = 0; j < state.conferenceRooms[i].programs.length; j++) {
          const program = state.conferenceRooms[i].programs[j];
          if (program.id === params.programId) {
            for (let k = 0; k < program.mediaItems.length; k++) {
              if (program.mediaItems[k].id === params.id) {
                program.mediaItems[k].isPaused = params.isPaused;
                break;
              }
            }
            return;
          }
        }
      }
    },

    setAllVideoMediaItemsIsPaused(state: TPromoProgramStoreState, params: { programId: number }): void {
      if (!state.conferenceRooms || !state.conferenceRooms.length) {
        return;
      }
      for (let i = 0; i < state.conferenceRooms.length; i++) {
        if (!state.conferenceRooms[i].programs || !state.conferenceRooms[i].programs.length) {
          continue;
        }
        for (let j = 0; j < state.conferenceRooms[i].programs.length; j++) {
          const program = state.conferenceRooms[i].programs[j];
          if (program.id === params.programId) {
            for (let k = 0; k < program.mediaItems.length; k++) {
              if (program.mediaItems[k].type === 'video' || program.mediaItems[k].type === 'broadcast') {
                program.mediaItems[k].isPaused = true;
              }
            }
            return;
          }
        }
      }
    },

    unpinMediaItem(state: TPromoProgramStoreState, params: { programId: number; id: string }): void {
      if (!state.conferenceRooms || !state.conferenceRooms.length) {
        return;
      }
      let program: TConferenceProgram = null;
      for (let i = 0; i < state.conferenceRooms.length; i++) {
        if (!state.conferenceRooms[i].programs || !state.conferenceRooms[i].programs.length) {
          continue;
        }
        for (let j = 0; j < state.conferenceRooms[i].programs.length; j++) {
          if (state.conferenceRooms[i].programs[j].id === params.programId) {
            program = state.conferenceRooms[i].programs[j];
            break;
          }
        }
      }
      if (!program) {
        return;
      }
      const item = program.mediaItems.find(mediaItem => {
        return mediaItem.id === params.id;
      });
      if (!item) {
        return;
      }

      item.isUnpinned = true;
      if (!state.unpinnedMediaItemsByProgramId[params.programId]) {
        state.unpinnedMediaItemsByProgramId[params.programId] = [];
      }
      const index = state.unpinnedMediaItemsByProgramId[params.programId].indexOf(item);
      if (index < 0) {
        state.unpinnedMediaItemsByProgramId[params.programId] = [
          ...state.unpinnedMediaItemsByProgramId[params.programId],
          item,
        ];
      }

      state.unpinnedMediaItemsByProgramId = Object.assign({}, state.unpinnedMediaItemsByProgramId);
      // program.mediaItems = [ ...program.mediaItems ];
    },

    pinMediaItem(state: TPromoProgramStoreState, params: { programId: number; id: string }): void {
      if (!state.conferenceRooms || !state.conferenceRooms.length) {
        return;
      }
      let program: TConferenceProgram = null;
      for (let i = 0; i < state.conferenceRooms.length; i++) {
        if (!state.conferenceRooms[i].programs || !state.conferenceRooms[i].programs.length) {
          continue;
        }
        for (let j = 0; j < state.conferenceRooms[i].programs.length; j++) {
          if (state.conferenceRooms[i].programs[j].id === params.programId) {
            program = state.conferenceRooms[i].programs[j];
            break;
          }
        }
      }
      if (!program) {
        return;
      }
      const item = program.mediaItems.find(mediaItem => {
        return mediaItem.id === params.id;
      });
      if (!item) {
        return;
      }

      item.isUnpinned = false;
      if (!state.unpinnedMediaItemsByProgramId[program.id]) {
        state.unpinnedMediaItemsByProgramId[program.id] = [];
      }
      state.unpinnedMediaItemsByProgramId[program.id] = [ ...state.unpinnedMediaItemsByProgramId[program.id].filter(mediaItem => {
        return mediaItem.id !== params.id;
      }) ];

      state.unpinnedMediaItemsByProgramId = Object.assign({}, state.unpinnedMediaItemsByProgramId);
      // program.mediaItems = [ ...program.mediaItems ];
    },

    setProgramIsFavorite(state: TPromoProgramStoreState, params: { programId: number; isFavorite: boolean }): void {
      for (let i = 0; i < state.conferenceRooms.length; i++) {
        if (!state.conferenceRooms[i].programs || !state.conferenceRooms[i].programs.length) {
          continue;
        }
        for (let j = 0; j < state.conferenceRooms[i].programs.length; j++) {
          if (state.conferenceRooms[i].programs[j].id === params.programId) {
            state.conferenceRooms[i].programs[j].is_favorite = params.isFavorite;
            state.conferenceRooms[i].programs[j] = Object.assign({}, state.conferenceRooms[i].programs[j]);
            state.conferenceRooms[i].programs = [ ...state.conferenceRooms[i].programs ];
            state.conferenceRooms = [ ...state.conferenceRooms ];
            return;
          }
        }
      }
    },

  },
};

export default promoProgramStore;
