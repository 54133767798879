import BaseApi from '@/_types/api/base.api.class';
import { AxiosRequestConfig, AxiosResponse } from 'axios';

// Needed for the newer Auth API
export type TSendLoginRequestParams = {
  login: string;
  password: string;
  platform: string;
  device_id: string;
  device_token?: string;
}

// Needed for the newer Auth API
export type TPasswordChangeConfirmRequestParams = {
  confirmation_id: string;
  code: string;
  password: string;
  platform: string;
  device_id: string;
  device_token?: string;
}

// Needed for the older Auth API
export type TConfirmCodeRequestParams = {
  confirmation_id: string;
  code: string;
  platform: string;
  device_id: string;
  device_token?: string;
}

export type TConfirmCodeResponseData = {
  token: string;
}

export class LoginApi extends BaseApi {

  /* Auth using login and password, API v 1.0.1 */
  public async loginReq(payload: TSendLoginRequestParams): Promise<{ token: string }> {
    const config: AxiosRequestConfig = {
      url: 'autoreg',
      method: 'POST',
      params: payload,
    };

    let response;
    try {
      response = await this.axios.request(config);
    } catch (error) {
      throw this.responseError(error);
    }

    this.token = response.data.token || null;
    return response.data;
  }

  /* Forgot password, API v 1.0.1 */
  public async forgotPasswordReq(login: string): Promise<{ confirmation_id: string }> {
    const config: AxiosRequestConfig = {
      url: 'forgot_password',
      method: 'POST',
      params: { login },
    };

    let response;
    try {
      response = await this.axios.request(config);
    } catch (error) {
      throw this.responseError(error);
    }

    return response.data;
  }

  /* Change the password, API v 1.0.1 */
  public async changePasswordReq(payload: TPasswordChangeConfirmRequestParams): Promise<{ token: string }> {
    const config: AxiosRequestConfig = {
      url: 'password_change_confirm',
      method: 'POST',
      params: payload,
    };

    let response;
    try {
      response = await this.axios.request(config);
    } catch (error) {
      throw this.responseError(error);
    }

    this.token = response.data.token || null;
    return response.data;
  }

  /* Old auth using only phone, API v 1.0.0 */
  public async phoneReq(phone: string): Promise<{ confirmation_id: string }> {
    const config: AxiosRequestConfig = {
      url: 'login',
      method: 'POST',
      params: { phone },
    };

    let response;
    try {
      response = await this.axios.request(config);
    } catch (error) {
      throw this.responseError(error);
    }

    return response.data;
  }

  public async confirmCode(confirmCodeParams: TConfirmCodeRequestParams): Promise<TConfirmCodeResponseData> {
    const config: AxiosRequestConfig = {
      url: 'login_confirm',
      method: 'POST',
      params: confirmCodeParams,
    };

    let response: AxiosResponse<TConfirmCodeResponseData>;
    try {
      response = await this.axios.request<TConfirmCodeResponseData>(config);
    } catch (error) {
      throw this.responseError(error);
    }

    this.token = response.data.token || null;
    return response.data;
  }

}

const loginApi = new LoginApi();
export default loginApi;
