import Vue from 'vue';
import './main.init';
import { VNode } from 'vue/types/vnode';
import { CreateElement } from 'vue/types/vue';
import { RenderContext } from 'vue/types/options';

import App from '@/App.vue';
import router from '@/router';
import store from '@/store';
import i18n from '@/i18n';
import '@/_styles/main.scss';

Vue.config.productionTip = false;
Vue.config.ignoredElements = [
  'seatplan-viewer',
];

Vue.component('vnode', {
  functional: true,
  render(h: CreateElement, context: RenderContext): VNode {
    // TODO
    // console.log('context.parent', context.parent);
    // if (context.parent.vNodeRendered) {
    //   context.parent.vNodeRendered();
    // }
    return context.props.node;
  }
});

new Vue({
  router,
  store,
  i18n,
  render: (h): VNode => h(App),
}).$mount('#app');

/* Load Google Maps on demand */
export const startGoogleMaps = function(): Promise<object> {
  return new Promise((resolve) => {
    const GoogleMapsApiKey = process.env.VUE_APP_GOOGLE_MAPS_API_KEY;
    const scriptId = 'BiletumGoogleMapsScript';

    const callBackFunctionName = 'BiletumGoogleMapsInit';
    const apiUrl = `https://maps.googleapis.com/maps/api/js?v=weekly&libraries=places&key=${GoogleMapsApiKey}&callback=${callBackFunctionName}`;
    // TODO: refactoring
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any)[callBackFunctionName] = resolve;

    if (document.getElementById(scriptId)) {
      resolve();
    } else {
      const s = document.createElement('script');
      s.setAttribute('src', apiUrl);
      s.setAttribute('id', scriptId);

      document.body.appendChild(s);
    }
  });
};

/* Load Baidu Maps on demand */
export const startBaiduMaps = function(): Promise<object> {
  return new Promise((resolve) => {
    const BaiduMapsApiKey = process.env.VUE_APP_BAIDU_MAPS_API_KEY;
    const scriptId = 'BiletumBaiduMapsScript';
    const callBackFunctionName = 'BiletumBaiduMapsInit';
    // TODO: refactoring
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any)[callBackFunctionName] = function(): void {
      // TODO: refactoring
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      resolve((window as any).BMap);
    };

    if (!document.getElementById(scriptId)) {
      const s = document.createElement('script');
      s.setAttribute('id', scriptId);
      s.setAttribute('type', 'text/javascript');
      s.setAttribute('src', 'https://api.map.baidu.com/api?v=2.0&ak=' + BaiduMapsApiKey + '&callback=' + callBackFunctionName);

      document.getElementsByTagName('head')[0].appendChild(s);
    } else if ('BMap' in window) {
      // TODO: refactoring
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      resolve((window as any).BMap);
    }

  });

};
