import api from '../../api';
import EventHelper from '../../_helpers/event.helper';

const eventStore = {
  strict: true,
  namespaced: true,
  state: {
    showPromoCheckAccessPopup: false,
    page_status: 'loading',
    categoryList: [],
    typeList: [],
    eventInfo: {
      access_type: 'free',
      address: '',
      agendas: '',
      categories: [
        {
          id: null,
          name: ''
        }
      ],
      city: {
        country_id: null,
        id: null,
        name: "",
        state_id: null
      },
      org_name: "",
      org_email: "",
      org_phone: "",
      org_logo: "",
      country: {
        capital: "",
        currency: "",
        id: null,
        iso2: "",
        iso3: "",
        name: "",
        phonecode: ""
      },
      date_end: "",
      date_start: "",
      descr: "",
      event_type: {
        id: null,
        name: "",
      },
      creator_user_id: -1,
      id: null,
      image: "",
      is_private: false,
      personal: {
        contacts: 0,
        is_creator: false,
        is_favorite: true,
        is_going: false,
        has_access: false,
        notes: 0,
        tickets: 0
      },
      photos: [],
      title: "",
      url: "",
      languages: ['en'],
    },
    eventList: [],
    eventListPast: [],
    eventError: '',
    eventsPageLoading: false,
    loadAddFavorite: false,
    loadDelFavorite: false,
    eventTags: [],
  },
  getters: {
    // getCategoryList: function (state) {
    // 	return state.categoryList;
    // },
    // getTypeList: function (state) {
    // 	return state.typeList;
    // },
    getEventInfo: function (state) {
      return state.eventInfo;
    }
  },
  mutations: {
    showPromoCheckAccessPopup(state, data) {
      state.showPromoCheckAccessPopup = data;
    },
    eventCategory(state, data) {
      state.categoryList = data;
    },
    setParticipation(state, data) {
      state.personal.is_going = data;
    },
    eventType(state, data) {
      state.typeList = data;
    },
    setEventList(state, data) {
      state.eventList = data;
    },
    setEventListPast(state, data) {
      state.eventListPast = data;
    },
    setEventTags(state, data) {
      state.eventTags = data;
    },
    setPageStatus(state, statusText) {
      state.page_status = statusText;
    },
    clearEventImage(state) {
      state.eventInfo.image = '';
    },
    clearOrgLogo(state) {
      state.eventInfo.org_logo = '';
    },
    setEvent(state, data) {
      localStorage.setItem("eventId", data.id); // TODO: old code. Remove, avoid localStorage

      let _agenda;
      let _country;

      if (data.country) {
        _country = {
          capital: data.country.capital,
          currency: data.country.currency,
          id: data.country.id,
          iso2: data.country.iso2,
          iso3: data.country.iso3,
          name: data.country.name,
          phonecode: data.country.phonecode
        }
      } else {
        _country = {
          capital: '',
          currency: '',
          id: null,
          iso2: null,
          iso3: null,
          name: '',
          phonecode: null
        };
      }
      state.eventInfo = {
        access_type: data.access_type,
        address: data.address,
        agendas: data.agendas,
        categories: (data.categories ? data.categories.map(item => {
          return item
        }) : []),
        city: (data.city ? {
          country_id: data.city.country_id,
          id: data.city.id,
          name: data.city.name,
          state_id: data.city.state_id
        } : {}),
        org_name: data.org_name,
        org_email: data.org_email,
        org_phone: data.org_phone,
        org_logo: data.org_logo,
        country: _country,
        date_end: data.date_end,
        date_start: data.date_start,
        descr: data.descr,
        event_type: {
          id: data.event_type.id,
          name: data.event_type.name,
        },
        creator_user_id: data.creator_user_id,
        id: data.id,
        image: data.image,
        is_private: data.is_private,
        personal: {
          contacts: data.personal.contacts,
          is_creator: data.personal.is_creator,
          is_favorite: data.personal.is_favorite,
          is_going: data.personal.is_going,
          has_access: data.personal.has_access,
          notes: data.personal.notes,
          tickets: data.personal.tickets
        },
        photos: data.photos,
        title: data.title,
        url: data.url,
        buy_ticket_url: data.buy_ticket_url,
        buy_booth_url: data.buy_booth_url,
        map: data.map || {},
        languages: data.languages || ['en'],
      };
    },
    setEventError(state, data) {
      state.eventError = data
    },
    loadingAddFav(state, data) {
      this.state.loadAddFavorite = data;
    },
    loadingDelFav(state, data) {
      this.state.loadDellFavorite = data
    },
    setEventsPageLoading(state, data){
      state.eventsPageLoading = data;

    },
    setEventsPageSuccess(state, data){
      state.eventsPageSuccess = data;

    }
  },
  actions: {
    clearEventImage: function ({commit}) {
      commit('clearEventImage', this.state);
    },
    clearOrgLogo: function ({commit}) {
      commit('clearOrgLogo', this.state);
    },
    toggleParticipation: async function ({commit, rootState}, data) {
      let token = rootState.authStore.token;
      data.token = token;
      await api.eventList.participate(data)
        .then((resolve) => {
          commit('setParticipation', resolve.List)
        }).catch((e) => {
          commit('setEventError', e.data);
        });
    },
    eventCategory: async function ({commit, rootState}) {
      let token = rootState.authStore.token;
      await api.createEvent.categoryList(token)
        .then((resolve) => {
          commit('eventCategory', resolve.List)
        }).catch((e) => {
          commit('setEventError', e.data);
        });
    },
    eventType: async function ({commit, rootState}) {
      let token = rootState.authStore.token;
      await api.createEvent.typeList(token)
        .then((resolve) => {
          commit('eventType', resolve.List)
        }).catch((e) => {
          commit('setEventError', e.data);
        });
    },
    createEvent: async function ({commit, rootState}, data) {
      let token = rootState.authStore.token;
      await api.createEvent.createEvent({token, data})
        .then((e) => {
          commit('setEvent', e);
          commit('setEventError', '');
        }).catch((e) => {
        commit('setEventError', e.data);
        })
    },
    callEventListMy: async function ({commit}, data) {
      commit('setEventsPageLoading', true);
      commit('setEventsPageSuccess', false);
      await api.eventList.eventList(data)
        .then((resolve) => {
          commit('setEventsPageLoading', false);
          commit('setEventsPageSuccess', true);
          commit('setEventList', resolve);
        }).catch((e) => {
          commit('setEventsPageSuccess', false);
          commit('setEventsPageLoading', false);
          commit('setEventError', e.data);
        });
    },
    callEventListMyPast: async function ({commit}, data) {
      await api.eventList.eventList(data, 'own')
        .then((resolve) => {
          commit('setEventListPast', resolve);
        }).catch((e) => {
          commit('setEventError', e.data);
        });
    },
    /* Requests single event info from the API */
    event: async function ({commit, rootState}, id, ) {
      let token = rootState.authStore.token;
      commit('setPageStatus', 'loading');

      await api.eventList.event({token, id})
        .then((resolve) => {

          // If the event has .event_domain, check it and redirect if it is fine.
          if (EventHelper.redirectToEventDomain(resolve)) {
            return;
          }

          commit('setEvent', resolve);
          commit('setPageStatus', 'ready');
        }).catch((e) => {
          commit('setEventError', e.data);
        });
    },
    updateEvent: async function ({commit}, data) {
      await api.updateEvent.update(data)
        .then(() => {
          commit('setEventError', '');
        }).catch((e) => {
        commit('setEventError', e.data);
        })
    },
    addFavorite: async function ({commit}, data) {
      commit('loadingAddFav', true);
      await api.updateEvent.addFavorite(data)
        .then(() => {
          commit('loadingAddFav', false);
          commit('setEventError', '');
        }).catch((e) => {
          commit('loadingAddFav', false);
          commit('setEventError', e.data);
        })
    },
    deleteFavorite: async function ({commit}, data) {
      commit('loadingDelFav', true);
      await api.updateEvent.deleteFavorite(data)
        .then(() => {
          commit('loadingDelFav', false);
        }).catch((e) => {
          commit('loadingDelFav', false);
          commit('setEventError', e.data);
        })
    },
    activateCode: async function ({commit}, data) {
      try{
        return await api.promoPage.activateCode(data.event_id, data.code);
      } catch(e) {
        commit('setEventError', e.data);
      }
    },
    showPromoCheckAccessPopup: function({commit}) {
      commit('showPromoCheckAccessPopup', true);
    },
    hidePromoCheckAccessPopup: function({commit}) {
      commit('showPromoCheckAccessPopup', false);
    },
    callEventTags: async function({commit}, eventId) {
      try{
        let tags = await api.event.getEventTags(eventId);
        commit("setEventTags", tags.data.List);
        return tags;
      } catch(e) {
        commit('setEventError', e.data);
      }
    }
  }
}

export default eventStore;

