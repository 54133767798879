import { render, staticRenderFns } from "./auth-popup.html?vue&type=template&id=4697c9ce&scoped=true&"
import script from "./auth-popup.vue?vue&type=script&lang=ts&"
export * from "./auth-popup.vue?vue&type=script&lang=ts&"
import style0 from "./auth-popup.scss?vue&type=style&index=0&id=4697c9ce&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4697c9ce",
  null
  
)

export default component.exports