import { RawLocation, Route } from 'vue-router';
import { RouteConfig } from 'vue-router/types/router';
import i18n from '@/i18n';
import Language from '@/_components/language/language.vue';
import RouterHelper from '@/_helpers/router.helper';

import statisticsRoutes from '@/_modules/statistics/routes/statistics.routes.ts';

const appRoutes: RouteConfig[] = [
  /* new */
  {
    path: '/',
    redirect: `/${i18n.locale}`,
  },
  {
    path: '/:lang',
    name: 'index',
    component: Language,
    redirect: { name: 'home' },
    children: [
      {
        path: '',
        name: 'home',
        meta: {
          showHeaderLogo: true, // TODO: refactoring - ?
          showHomeSiteIntro: true, // TODO: refactoring - ?
        },
        component: (): Promise<object> => import('@/_components/home/home.vue'),
      },
      {
        path: 'seat-plan',
        name: 'seat-plan',
        meta: {
          showNavigation: false,
        },
        component: (): Promise<object> => import('@/_components/seat-plan/seat-plan.vue'),
      },
      {
        path: 'auth',
        name: 'auth-page',
        component: (): Promise<object> => import('@/_components/auth-page/auth-page.vue'),
      },
      {
        path: 'support',
        name: 'support',
        meta: {
          showNavigation: false,
          showErrorDisplayer: false,
          rootElementClasses: [ 'app-standalone-page' ],
        },
        component: (): Promise<object> => import('@/_components/support/support.vue'),
      },

      /* old */
      {
        path: 'events',
        component: (): Promise<object> => import('@/_modules/events/components/events/events.vue'),
        children: [
          {
            path: '',
            name: 'event-list',
            component: (): Promise<object> => import('../views/EventList.vue'),
            beforeEnter: RouterHelper.authorized,
            meta: {
              showHeaderLogo: true, // TODO: refactoring - ?
            },
          },
          {
            path: 'create',
            name: 'event-create',
            component: (): Promise<object> => import('../views/CreateEvent.vue'),
            beforeEnter: RouterHelper.authorized,
          },
          {
            path: ':eventId',
            component: (): Promise<object> => import('@/_modules/events/components/event/event.vue'),
            children: [
              {
                path: '',
                name: 'event-info',
                component: (): Promise<object> => import('../views/EventPage.vue'),
              },
              {
                path: 'tickets',
                name: 'event-tickets',
                component: (): Promise<object> => import('../views/TicketsList.vue'),
                beforeEnter: RouterHelper.authorized,
              },
              {
                path: 'addticket',
                name: 'create-ticket',
                component: (): Promise<object> => import('../views/CreateTicket.vue'),
                beforeEnter: RouterHelper.authorized,
              },
              {
                path: 'ticket/:id_ticket',
                name: 'view-ticket',
                component: (): Promise<object> => import('../views/TicketPage.vue'),
                beforeEnter: RouterHelper.authorized,
              },
              {
                path: 'notes',
                component: (): Promise<object> => import('../views/components/viewers/NoteViewer.vue'),
                beforeEnter: RouterHelper.authorized,
                children: [
                  {
                    path: '',
                    name: 'notes-list',
                    component: (): Promise<object> => import('../views/NoteList.vue'),
                    beforeEnter: RouterHelper.authorized,
                  },
                ],
              },

              // {
              //   path: 'contacts',
              //   name: '',
              //   component: () => import('../views/components/viewers/ContactViewer'),
              //   beforeEnter: RouterHelper.authorized,
              //   children: [
              //     {
              //       path: '',
              //       name: 'contacts-list',
              //       component: () => import('../views/ContactList'),
              //       beforeEnter: RouterHelper.authorized
              //     },
              //     {
              //       path: 'create',
              //       name: 'contact-create',
              //       component: () => import('../views/components/contacts/createContact'),
              //       beforeEnter: RouterHelper.authorized
              //     },
              //     {
              //       path: ':contactId/edit',
              //       name: 'contact-edit',
              //       component: () => import('../views/components/contacts/createContact'),
              //       beforeEnter: RouterHelper.authorized
              //     },
              //     {
              //       path: ':contactId/info',
              //       name: 'contact-info',
              //       component: () => import('../views/components/contacts/contactInfo'),
              //       beforeEnter: RouterHelper.authorized
              //     },
              //   ]
              // },

              ...statisticsRoutes,

              {
                path: '/:lang/events/:eventId/promo/live',
                name: 'promo-live',
                meta: {
                  rootElementClasses: [ // see App.vue → getAdditionalRootElementClasses
                    'app-promo-live-ev[:eventId]',
                  ],
                },
                component: (): Promise<object> => import('../views/PromoLive.vue'),
                beforeEnter: RouterHelper.authorized,
              },

              {
                path: 'promo',
                name: '',
                redirect: (to: Route): RawLocation => ({ name: 'promo-page-events-companies', params: { eventId: to.params.eventId } }),
                component: (): Promise<object> => import('@/_modules/promo/components/promo/promo.vue'),
                beforeEnter: RouterHelper.authorized,
                children: [
                  {
                    path: 'hall',
                    name: '',
                    component: (): Promise<object> => import('../views/PromoEvents.vue'),
                    beforeEnter: RouterHelper.authorized,
                    children: [
                      {
                        path: '',
                        name: 'promo-page-events-companies',
                        meta: {
                          showBiletumSearch: true,
                        },
                        component: (): Promise<object> => import('../views/components/promoPage/companies/companies.vue'),
                        beforeEnter: RouterHelper.authorized,
                        children: [
                          {
                            path: ':external_id',
                            name: 'promo-page-events-company',
                            meta: {
                              showBiletumSearch: true,
                            },
                            // component: () => import('../views/components/promoPage/companies/company'),
                            components: {
                              a: (): Promise<object> => import('../views/components/promoPage/companies/company.vue'),
                              // b: () => import('../views/components/promoPage/profile/profile'),
                            },
                            beforeEnter: RouterHelper.authorized,
                            children: [
                              {
                                path: ':contact_id',
                                name: 'promo-profile',
                                meta: {
                                  showBiletumSearch: true,
                                },
                                // component: () => import('../views/components/promoPage/profile/profile'),q
                                components: {
                                  // a: () => import('../views/components/promoPage/companies/company'),
                                  b: (): Promise<object> => import('../views/components/promoPage/profile/profile.vue'),
                                },
                                beforeEnter: RouterHelper.authorized,
                              },
                            ],
                          },
                          // {
                          //   path: ':external_id/:contact_id',
                          //   name: 'promo-profile',
                          //   // component: () => import('../views/components/promoPage/profile/profile'),
                          //   components: {
                          //     a: () => import('../views/components/promoPage/companies/company'),
                          //     b: () => import('../views/components/promoPage/profile/profile'),
                          //   },
                          //   beforeEnter: RouterHelper.authorized,
                          // }
                        ],
                      },
                    ],
                  },
                  {
                    path: 'program',
                    name: 'promo-program',
                    component: (): Promise<object> => import('@/_modules/promo-program/components/promo-program/promo-program.vue'),
                    beforeEnter: RouterHelper.authorized,
                  },
                  {
                    path: 'program/:date',
                    name: 'promo-program-date',
                    component: (): Promise<object> => import('@/_modules/promo-program/components/promo-program/promo-program.vue'),
                    beforeEnter: RouterHelper.authorized,
                  },
                  {
                    path: 'program/:date/:programId',
                    name: 'promo-program-date-program',
                    component: (): Promise<object> => import('@/_modules/promo-program/components/promo-program/promo-program.vue'),
                    beforeEnter: RouterHelper.authorized,
                  },
                  {
                    path: 'program/:date/:programId/:contact_id',
                    name: 'promo-program-date-program-contact',
                    component: (): Promise<object> => import('@/_modules/promo-program/components/promo-program/promo-program.vue'),
                    beforeEnter: RouterHelper.authorized,
                  },
                  {
                    path: 'contacts',
                    name: '',
                    component: (): Promise<object> => import('../views/PromoContacts.vue'),
                    beforeEnter: RouterHelper.authorized,
                    children: [
                      {
                        path: 'messages',
                        name: 'promo-page-events-contacts-messages',
                        meta: {
                          showMyMessagesTab: false
                        },
                        component: (): Promise<object> => import('../views/components/promoPage/contacts/contacts.vue'),
                        beforeEnter: RouterHelper.authorized
                      },
                      {
                        path: '',
                        name: 'promo-page-events-contacts',
                        meta: {
                          showMyMessagesTab: false,
                        },
                        component: (): Promise<object> => import('../views/components/promoPage/contacts/contacts.vue'),
                        beforeEnter: RouterHelper.authorized,
                        children: [
                          {
                            path: ':contact_id',
                            name: 'promo-page-contacts-contact',
                            // component: () => import('../views/components/promoPage/companies/company'),
                            meta: {
                              showMyMessagesTab: false,
                            },
                            components: {
                              a: (): Promise<object> => import('../views/components/promoPage/contacts/contact.vue'),
                              // b: () => import('../views/components/promoPage/profile/profile'),
                            },
                            beforeEnter: RouterHelper.authorized,
                            children: [
                              {
                                path: ':external_id',
                                name: 'promo-contact-company',
                                meta: {
                                  showMyMessagesTab: false,
                                },
                                // component: () => import('../views/components/promoPage/profile/profile'),q
                                components: {
                                  // a: () => import('../views/components/promoPage/companies/company'),
                                  b: (): Promise<object> => import('../views/components/promoPage/contacts/contactCompany.vue'),
                                },
                                beforeEnter: RouterHelper.authorized,
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    path: 'calendar',
                    name: 'promo-page-calendar',
                    component: (): Promise<object> => import('../views/PromoCalendar.vue'),
                    beforeEnter: RouterHelper.authorized,
                    children: [
                      {
                        path: 'meeting-invite/:inviteKey',
                        name: 'meeting-invite',
                        meta: {
                          showNavigation: false,
                        },
                        component: (): Promise<object> => import('../views/PromoCalendar.vue'),
                        beforeEnter: RouterHelper.authorized,
                      },
                      {
                        path: ':contact_id',
                        name: 'promo-page-calendar-contact',
                        components: {
                          // default: (): Promise<object> => import('../views/PromoCalendar'),
                          contactCard: (): Promise<object> => import('../views/components/promoPage/contacts/contact.vue'),
                        },
                        beforeEnter: RouterHelper.authorized,
                      },
                    ],
                  },
                  {
                    path: 'editold',
                    name: 'promo-page-edit-old',
                    component: (): Promise<object> => import('../views/PromoEditContact.vue'),
                    beforeEnter: RouterHelper.authorized,
                  },
                  {
                    path: 'edit',
                    name: 'promo-page-edit',
                    redirect: {name: 'promo-page-edit-contact'},
                    component: (): Promise<object> => import('../views/PromoEditContactAndCompany.vue'),
                    beforeEnter: RouterHelper.authorized,
                    children: [
                      {
                        path: 'contact',
                        name: 'promo-page-edit-contact',
                        component: (): Promise<object> => import('../views/PromoEditContactAndCompany.vue'),
                        beforeEnter: RouterHelper.authorized,
                      },
                      {
                        path: 'company',
                        name: 'promo-page-edit-company',
                        component: (): Promise<object> => import('../views/PromoEditContactAndCompany.vue'),
                        beforeEnter: RouterHelper.authorized,
                      },
                    ]
                  },
                  {
                    path: 'cabinet',
                    name: 'promo-page-cabinet',
                    redirect: {name: 'promo-page-cabinet-main-page'},
                    component: (): Promise<object> => import('@/_modules/promo-cabinet/components/cabinet/cabinet.vue'),
                    beforeEnter: RouterHelper.authorized,
                    children: [
                      {
                        path: 'main-page',
                        name: 'promo-page-cabinet-main-page',
                        component: (): Promise<object> => import('@/_modules/promo-cabinet/components/cabinet-main-page/cabinet-main-page.vue'),
                        beforeEnter: RouterHelper.authorized,
                      },
                      {
                        path: 'lobby',
                        name: 'promo-page-cabinet-lobby',
                        component: (): Promise<object> => import('@/_modules/promo-cabinet/components/cabinet-lobby/cabinet-lobby.vue'),
                        beforeEnter: RouterHelper.authorized,
                      },
                      {
                        path: 'exponents',
                        name: 'promo-page-cabinet-exponents',
                        component: (): Promise<object> => import('@/_modules/promo-cabinet/components/cabinet-exponents/cabinet-exponents.vue'),
                        beforeEnter: RouterHelper.authorized,
                      },
                      {
                        path: 'program',
                        name: 'promo-page-cabinet-program',
                        component: (): Promise<object> => import('@/_modules/promo-cabinet/components/cabinet-program/cabinet-program.vue'),
                        beforeEnter: RouterHelper.authorized,
                      },
                      {
                        path: 'program/create',
                        name: 'promo-page-cabinet-program-create',
                        component: (): Promise<object> => import('@/_modules/promo-cabinet/components/cabinet-program-form/cabinet-program-form.vue'),
                        beforeEnter: RouterHelper.authorized,
                        meta: {
                          hideCabinetNavigation: true,
                        },
                      },
                      {
                        path: 'program/edit',
                        name: 'promo-page-cabinet-program-edit',
                        component: (): Promise<object> => import('@/_modules/promo-cabinet/components/cabinet-program-form/cabinet-program-form.vue'),
                        beforeEnter: RouterHelper.authorized,
                      },
                      {
                        path: 'program/:date',
                        name: 'promo-page-cabinet-program-date',
                        component: (): Promise<object> => import('@/_modules/promo-cabinet/components/cabinet-program/cabinet-program.vue'),
                        beforeEnter: RouterHelper.authorized,
                      },
                      {
                        path: 'program/:date/create',
                        name: 'promo-page-cabinet-program-date-create',
                        component: (): Promise<object> => import('@/_modules/promo-cabinet/components/cabinet-program-form/cabinet-program-form.vue'),
                        beforeEnter: RouterHelper.authorized,
                        meta: {
                          hideCabinetNavigation: true,
                        },
                      },
                      {
                        path: 'program/:date/edit',
                        name: 'promo-page-cabinet-program-date-edit',
                        component: (): Promise<object> => import('@/_modules/promo-cabinet/components/cabinet-program-form/cabinet-program-form.vue'),
                        beforeEnter: RouterHelper.authorized,
                      },
                    ]
                  },
                ],
              },

            ],
          },
        ],
      },
      /* new */
      {
        path: '*',
        name: '*',
        component: (): Promise<object> => import('@/_components/page-not-found/page-not-found.vue'),
      },
    ],
  },
];

export default appRoutes;
