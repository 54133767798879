import { TAppStoreState } from '@/_types/store/app-store-state.type';
import { ActionContext, Module } from 'vuex';
import { TMessagesList } from '@/_types/messages-list.type';

import { TStoreEntityState } from '@/_types/store/store-entity-state.type';
import messagesApi, { TCallMessageListParams, TCallUserMessageListParams, TSendMessageParams } from '@/_api/messages.api';
import AxiosCancellableRequest from '@/_types/api/axios-cancellable-request.class';

export type TMessagesStoreState = {
  messagesListEntity: TStoreEntityState<TMessagesList>;
  messagesUserListEntity: TStoreEntityState<TMessagesList>;
  sendMessageEntity: TStoreEntityState<null>;
}

const messagesListRequest = new AxiosCancellableRequest<TCallMessageListParams, TMessagesList>(messagesApi.callMessageList.bind(messagesApi));
const messagesUserListRequest = new AxiosCancellableRequest<TCallUserMessageListParams, TMessagesList>(messagesApi.callUserMessageList.bind(messagesApi));
const sendMessageRequest = new AxiosCancellableRequest<TSendMessageParams, void >(messagesApi.sendMessage.bind(messagesApi));

const messageStore: Module<TMessagesStoreState, TAppStoreState> = {
  namespaced: true,
  state: {
    messagesListEntity: {
      isLoading: false,
      data: null,
      error: null,
    },
    messagesUserListEntity: {
      isLoading: false,
      data: null,
      error: null,
    },
    sendMessageEntity: {
      isLoading: false,
      error: null,
    }
  },
  getters: {
    messages: (state: TMessagesStoreState): TMessagesList => {
      return state.messagesListEntity.data;
    },
    userMessages: (state: TMessagesStoreState): TMessagesList => {
      return state.messagesUserListEntity.data;
    },
    userMessagesIsLoading: (state: TMessagesStoreState): boolean => {
      return state.sendMessageEntity.isLoading;
    },
  },
  actions: {

    callMessageList: async (context: ActionContext<TMessagesStoreState, TAppStoreState>, params: TCallMessageListParams): Promise<void> => {
      const { commit } = context;

      commit('messagesListRequest');
      let data;
      try {
        data = await messagesListRequest.load(params);
      } catch (error) {
        commit('messagesListError', error);
      } finally {
        commit('messagesList', data);
      }

    },
    callUserMessageList: async (context: ActionContext<TMessagesStoreState, TAppStoreState>, params: TCallUserMessageListParams): Promise<void> => {
      const { commit } = context;

      commit('messagesUserListRequest');
      let data;
      try {
        data = await messagesUserListRequest.load(params);
      } catch (error) {
        commit('messagesUserListError', error);
      } finally {
        commit('messagesUserList', data);
      }

    },

    sendMessage: async (context: ActionContext<TMessagesStoreState, TAppStoreState>, params: TSendMessageParams): Promise<void> => {
      const { commit } = context;

      commit('sendMessageRequest');
      let data;
      try {
        data = await sendMessageRequest.load(params);
      } catch (error) {
        commit('sendMessageError', error);
      } finally {
        commit('sendMessage', data);
      }
    },
  },
  mutations: {
    messagesListRequest(state: TMessagesStoreState): void{
      // state.messagesListEntity.data = null;
      state.messagesListEntity.isLoading = false;
      state.messagesListEntity.error = null;
    },
    messagesListError(state: TMessagesStoreState, error: Error): void {
      state.messagesListEntity.error = error;
    },
    messagesList(state: TMessagesStoreState, messages: TMessagesList): void {
      state.messagesListEntity.data = messages;
      state.messagesListEntity.isLoading = false;
    },

    messagesUserListRequest(state: TMessagesStoreState): void{
      // state.messagesUserListEntity.data = null;
      state.messagesUserListEntity.isLoading = false;
      state.messagesUserListEntity.error = null;
    },
    messagesUserListError(state: TMessagesStoreState, error: Error): void {
      state.messagesUserListEntity.error = error;
    },
    messagesUserList(state: TMessagesStoreState, messages: TMessagesList): void {
      if (!messages) { return; }

      const { Limit, Offset, Total, List } = messages;

      state.messagesUserListEntity.data = {
        Limit: Limit,
        Offset: Offset,
        Total: Total,
        List: List.slice().reverse()
      };
      state.messagesUserListEntity.isLoading = false;

    },
    sendMessageRequest(state: TMessagesStoreState): void{
      state.sendMessageEntity.isLoading = true;
      state.sendMessageEntity.error = null;
    },
    sendMessageError(state: TMessagesStoreState, error: Error): void {
      state.sendMessageEntity.error = error;
    },
    sendMessage(state: TMessagesStoreState): void {
      state.sendMessageEntity.isLoading = false;
    },
  },
};

export default messageStore;
