import BaseApi from '@/_types/api/base.api.class';
import { TUser } from '@/_types/user.type';
import { AxiosRequestConfig, CancelToken } from 'axios';

export type TGetUserInfoParams = {
  id?: number;
  company_id?: number;
}

export class UserApi extends BaseApi {

  public async getUserInfo(params: TGetUserInfoParams, cancelToken?: CancelToken): Promise<TUser> {
    const config: AxiosRequestConfig = {
      url: '/user/info',
      method: 'GET',
      cancelToken,
    };

    let response;
    try {
      response = await this.axios.request<TUser>(config);
    } catch (error) {
      throw this.responseError(error);
    }

    return response.data;
  }

}

const userApi = new UserApi();
export default userApi;
