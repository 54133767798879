


import Component from 'vue-class-component';
import { Vue } from 'vue-property-decorator';
import DeviceHelper from '@/_helpers/device.helper';
import CookiesHelper from '@/_helpers/cookies-helper';

const MOB_APP_INVITER_COOKIE_NAME = 'mobile_app_inviter_shown';
const MOB_APP_INVITER_COOKIE_VALUE = '1';

@Component({
  name: 'mobile-app-inviter',
})
export default class MobileAppInviter extends Vue {

  public showFlag: boolean = true;

  public get appUrlIos(): string {
    return 'https://apps.apple.com/app/biletum/id1506448963?l=' + this.$i18n.locale;
  }

  public get appUrlAndroid(): string {
    return 'https://play.google.com/store/apps/details?id=com.biletum&hl=' + this.$i18n.locale;
  }

  public get isMobileAppInviterShown(): boolean {
    const hasBeenShown: boolean = (CookiesHelper.readCookie(MOB_APP_INVITER_COOKIE_NAME) === '1');
    return this.showFlag && !hasBeenShown && DeviceHelper.isUserAgentMobile() && (this.isIOS || this.isAndroid);
  }

  public get isIOS(): boolean {
    return DeviceHelper.isIOS();
  }

  public get isAndroid(): boolean {
    return DeviceHelper.isAndroid();
  }

  public appButtonClickHandler(): void {

    if (this.isIOS) {
      window.location.href = this.appUrlIos;
    } else if (this.isAndroid) {
      window.location.href = this.appUrlAndroid;
    } else {
      this.showFlag = false;
    }

  }

  public proceedButtonClickHandler(): void {
    CookiesHelper.createCookie({
      name: MOB_APP_INVITER_COOKIE_NAME,
      value: MOB_APP_INVITER_COOKIE_VALUE,
    });
    this.showFlag = false;
  }
}
