import VueRouter, { Route } from 'vue-router';
import RouterHelper from '@/_helpers/router.helper';

import appRoutes from '@/_routes/app.routes';

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: appRoutes,
  // TODO: refactoring - scrollBehavior is not RouterOptions
  // @ts-ignore
  scrollBehavior(to: Route, from: Route, savedPosition: { x: number; y: number }): { x: number; y: number } {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});
router.beforeEach(RouterHelper.setLang);

export default router;
