import BaseApi from '@/_types/api/base.api.class';
import { AxiosRequestConfig, AxiosResponse, CancelToken } from 'axios';
import { TEventStat } from '@/_modules/statistics/types/event-stat.type';
import { TPersonalStat } from '@/_modules/statistics/types/personal-stat.type';
import { TPromoPageStat } from '@/_modules/statistics/types/promo-page-stat.type';

export type TPutStatParams = {
  eventId: number;
  actionName: string;
  data: any;
}

export type TGetStatParams = {
  eventId: number;
}

export type TGetStatPersonalParams = {
  eventId: number;
}

export type TGetStatPromopageParams = {
  eventId: number;
  externalId: string;
}

export type TExportContactsParams = {
  eventId: number;
}
export type TExportPromopagesParams = {
  eventId: number;
}
export type TExportMessagesParams = {
  eventId: number;
}
export type TExportMeetingsParams = {
  eventId: number;
}
export type TExportNotesParams = {
  eventId: number;
}

export class StatisticsApi extends BaseApi {

  public async putStat(params: TPutStatParams): Promise<void> {
    const { eventId, actionName, data } = params;
    const config: AxiosRequestConfig = {
      method: 'PUT',
      url: `/event/${eventId}/stat`,
      params: {
        event_id: eventId,
        action_name: actionName,
        data
      },
    };

    try {
      await this.axios.request<void>(config);
    } catch (error) {
      throw this.responseError(error);
    }
  }

  public async getStat(params: TGetStatParams, cancelToken?: CancelToken): Promise<TEventStat> {
    const { eventId } = params;
    const config: AxiosRequestConfig = {
      method: 'GET',
      url: `/event/${eventId}/stat`,
      cancelToken,
    };

    let response;
    try {
      response = await this.axios.request <TEventStat>(config);
    } catch (error) {
      throw this.responseError(error);
    }
    return response.data;
  };

  public async getStatPersonal(params: TGetStatPersonalParams, cancelToken?: CancelToken): Promise<TPersonalStat> {
    const { eventId } = params;
    const config: AxiosRequestConfig = {
      method: 'GET',
      url: `/event/${eventId}/stat/personal`,
      cancelToken,
    };

    let response;
    try {
      response = await this.axios.request<TPersonalStat>(config);
    } catch (error) {
      throw this.responseError(error);
    }
    return response.data;
  };

  public async getStatPromopage(params: TGetStatPromopageParams, cancelToken?: CancelToken): Promise<TPromoPageStat> {
    const { eventId, externalId } = params;

    const config: AxiosRequestConfig = {
      method: 'GET',
      url: `/event/${eventId}/stat/promopage/${externalId}`,
      cancelToken
    };

    let response;
    try {
      response = await this.axios.request<TPromoPageStat>(config);
    } catch (error) {
      throw this.responseError(error);
    }
    return response.data;
  };

  public async exportContact(params: TExportContactsParams, cancelToken?: CancelToken): Promise<File> {
    const { eventId } = params;
    const config: AxiosRequestConfig = {
      method: 'GET',
      url: `/event/${eventId}/export/contacts`,
      responseType: 'blob',
      params: {
        format: 'csv',
      },
      cancelToken
    };

    let response;
    try {
      response = await this.axios.request<File>(config);
    } catch (error) {
      throw this.responseError(error);
    }
    return response.data;
  };

  public async exportEventContacts(params: TExportContactsParams, cancelToken?: CancelToken): Promise<File> {
    const { eventId } = params;
    const config: AxiosRequestConfig = {
      method: 'GET',
      url: `/event/${eventId}/export/contacts/all`,
      responseType: 'blob',
      params: {
        format: 'csv',
      },
      cancelToken
    };

    let response;
    try {
      response = await this.axios.request<File>(config);
    } catch (error) {
      throw this.responseError(error);
    }
    return response.data;
  };

  public async exportPromopage(params: TExportPromopagesParams, cancelToken?: CancelToken): Promise<File> {
    const { eventId } = params;
    const config: AxiosRequestConfig = {
      method: 'GET',
      url: `/event/${eventId}/export/promopages`,
      responseType: 'blob',
      params: {
        format: 'csv',
      },
      cancelToken
    };

    let response: AxiosResponse<File>;
    try {
      response = await this.axios.request<File>(config);
    } catch (error) {
      throw this.responseError(error);
    }
    return response.data;
  };

  public async exportMessages(params: TExportMessagesParams, cancelToken?: CancelToken): Promise<File> {
    const { eventId } = params;
    const config: AxiosRequestConfig = {
      method: 'GET',
      url: `/event/${eventId}/export/messages`,
      responseType: 'blob',
      params: {
        format: 'csv',
      },
      cancelToken
    };

    let response;
    try {
      response = await this.axios.request<File>(config);
    } catch (error) {
      throw this.responseError(error);
    }
    return response.data;
  };

  public async exportMeetings(params: TExportMeetingsParams, cancelToken?: CancelToken): Promise<File> {
    const { eventId } = params;
    const config: AxiosRequestConfig = {
      method: 'GET',
      url: `/event/${eventId}/export/meetings`,
      responseType: 'blob',
      params: {
        format: 'csv',
      },
      cancelToken
    };

    let response;
    try {
      response = await this.axios.request<File>(config);
    } catch (error) {
      throw this.responseError(error);
    }
    return response.data;
  };

  public async exportNotes(params: TExportNotesParams, cancelToken?: CancelToken): Promise<File> {
    const { eventId } = params;
    const config: AxiosRequestConfig = {
      method: 'GET',
      url: `/event/${eventId}/export/notes`,
      responseType: 'blob',
      params: {
        format: 'csv',
      },
      cancelToken
    };

    let response;
    try {
      response = await this.axios.request<File>(config);
    } catch (error) {
      throw this.responseError(error);
    }
    return response.data;
  };

}

const statisticsApi = new StatisticsApi();

export default statisticsApi;
