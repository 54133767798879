import { ActionContext, Module } from 'vuex';
import { TAppStoreState } from '@/_types/store/app-store-state.type';
import AxiosCancellableRequest from '@/_types/api/axios-cancellable-request.class';
import badgeNotificationsApi from '@/_api/notifications/bageNotifications.api';
import { TBadgeNotificationsStoreState } from '@/_modules/notifications-popup/types/bage-notifications-store-state.type';
import { TBadgeNotifications } from '@/_modules/notifications-popup/types/badgeNotifications.type';

let _unreadCount: TBadgeNotifications = {
  messages_count: 0,
  meeting_count: 0,
};

const getUnreadCountRequest = new AxiosCancellableRequest(badgeNotificationsApi.getUnreadCount.bind(badgeNotificationsApi));

const badgeNotificationsStore: Module<TBadgeNotificationsStoreState, TAppStoreState> = {
  namespaced: true,
  state: {
    badgeNotificationsPollCount: 0,
    badgeNotificationsMessagesCount: 0,
    badgeNotificationsMeetingsCount: 0,
    badgeNotifications: {
      isLoading: false,
      data: {
        messages_count: 0,
        meeting_count: 0,
      },
      error: null,
    },
  },
  getters: {
    getMessagesCount: (state): number => {
      return state.badgeNotificationsMessagesCount;
    },
    getMeetingsCount: (state): number => {
      return state.badgeNotificationsMeetingsCount;
    },
    getNotifications: (state: TBadgeNotificationsStoreState): TBadgeNotifications => {
      return state.badgeNotifications.data;
    },
    getNotificationsPollData: (state: TBadgeNotificationsStoreState): number => {
      return state.badgeNotificationsPollCount;
    },
  },
  actions: {
    getBadgeNotificationsCount: async (context: ActionContext<TBadgeNotificationsStoreState, TAppStoreState>, eventId: number): Promise<void> => {
      const { commit } = context;
      commit('unreadCountRequest');
      let data;
      try {
        data = await getUnreadCountRequest.load(eventId);
      } catch (error) {
        commit('unreadCountError', error);
      } finally {
        commit('unreadCount', data);
      }
    }
  },
  mutations: {
    unreadCountRequest(state: TBadgeNotificationsStoreState): void {
      state.badgeNotifications.isLoading = true;
      state.badgeNotifications.error = null;
    },
    unreadCountError(state: TBadgeNotificationsStoreState, error: Error): void {
      state.badgeNotifications.error = error;
      state.badgeNotificationsMessagesCount = 0;
      state.badgeNotificationsMeetingsCount = 0;
    },
    unreadCount(state: TBadgeNotificationsStoreState, unreadCount: TBadgeNotifications): void {
      if (_unreadCount) {
        state.badgeNotifications.data = _unreadCount;
        state.badgeNotifications.isLoading = false;

        state.badgeNotificationsMessagesCount = _unreadCount.messages_count;
        state.badgeNotificationsMeetingsCount = _unreadCount.meeting_count;
        state.badgeNotificationsPollCount += 1;
      }
      _unreadCount = unreadCount;

    },
  }

};

export default badgeNotificationsStore;
