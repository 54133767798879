import { AxiosRequestConfig, CancelToken } from 'axios';
import BaseApi from '@/_types/api/base.api.class';
import { TContact } from '@/_types/contact.type';
import { TApiListResponse } from '@/_types/api/api-list-response.type';

export type TGetContactParams = {
  eventId: number;
  contactId: number;
}

export type TGetAllEventContactsParams = {
  eventId: number;
};

export class ContactsApi extends BaseApi {

  public async getContact(params: TGetContactParams, cancelToken?: CancelToken): Promise<TContact> {
    const { eventId, contactId } = params;
    const config: AxiosRequestConfig = {
      url: `event/${eventId}/contact/${contactId}/info`,
      method: 'GET',
      cancelToken,
    };

    let response;
    try {
      response = await this.axios.request<TContact>(config);
    } catch (error) {
      throw this.responseError(error);
    }

    return response.data;
  }

  public async getAllEventContacts(params: TGetAllEventContactsParams, cancelToken?: CancelToken): Promise<TContact[]> {
    const { eventId } = params;
    const config: AxiosRequestConfig = {
      url: `/event/${eventId}/contact/list/all`,
      method: 'GET',
      cancelToken,
    };

    let response;
    try {
      response = await this.axios.request<TApiListResponse<TContact>>(config);
    } catch (error) {
      throw this.responseError(error);
    }
    return response.data.List;
  }

}

const contactsApi = new ContactsApi();
export default contactsApi;
