import { TContact } from '@/_types/contact.type';

export default class ContactHelper {

  public static getFullName(contact: TContact): string {
    if (!contact) {
      return '';
    }
    const { name, surname } = contact;
    const nameCopy = ('' + (name || '')).trim();
    const surnameCopy = ('' + (surname || '')).trim();

    let fullName = nameCopy;
    if (surnameCopy) {
      fullName += ' ' + surnameCopy;
    }

    return fullName;
  }

  public static getFullCompany(contact: TContact): string {
    if (!contact) {
      return '';
    }
    const { company_name, company_position } = contact;
    const nameCopy = ('' + (company_name || '')).trim();
    const positionCopy = ('' + (company_position || '')).trim();

    let fullCompany = nameCopy;
    if (positionCopy) {
      fullCompany += ', ' + positionCopy;
    }

    return fullCompany;
  }

}
