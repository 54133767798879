import { ActionContext, Module } from 'vuex';
import { TPromoPageStoreState } from '@/_modules/promo/types/promo-page-store-state.type';
import { TAppStoreState } from '@/_types/store/app-store-state.type';
import { TContact } from '@/_types/contact.type';
import {TPromoPage, TCodeList} from '@/_types/promo-page/promo-page.type';
import eventApi, { TGetEventContactParams } from '@/_modules/events/api/event/event.api';
import AxiosCancellableRequest from '@/_types/api/axios-cancellable-request.class';
import promoPageApi, {
  TGetContactPromoPageParams,
  TCreatePromoPageParams,
  TPromopageCodeListParams,
  TGetAllEventPromoPagesParams, TDeletePromoPageParams, TDeletePromoPageByCodeParams
} from '@/_modules/promo/api/promo-page.api';
import {TApiListResponse} from '@/_types/api/api-list-response.type';
import ContactHelper from '@/_helpers/contact.helper';

const getEventContactRequest = new AxiosCancellableRequest<TGetEventContactParams, TContact>(eventApi.getContact.bind(eventApi));
const getContactPromoPageRequest = new AxiosCancellableRequest<TGetContactPromoPageParams, TPromoPage>(promoPageApi.getContactPromoPage.bind(promoPageApi));
const createPromoPageRequest = new AxiosCancellableRequest<TCreatePromoPageParams, TPromoPage>(promoPageApi.createPromoPage.bind(promoPageApi));
const deletePromoPageRequest = new AxiosCancellableRequest<TDeletePromoPageParams, void>(promoPageApi.deletePromoPage.bind(promoPageApi));
const deletePromoPageByCodeRequest = new AxiosCancellableRequest<TDeletePromoPageByCodeParams, void>(promoPageApi.deletePromoPageByCode.bind(promoPageApi));
const promoPageListAllRequest = new AxiosCancellableRequest<TGetAllEventPromoPagesParams, TPromoPage[]>(promoPageApi.getAllEventPromoPages.bind(promoPageApi));
const getPromoPageCodeListRequest = new AxiosCancellableRequest<TPromopageCodeListParams, TApiListResponse<TCodeList[]>>(promoPageApi.promoPageCodeList.bind(promoPageApi));

const promoPageStore: Module<TPromoPageStoreState, TAppStoreState> = {
  namespaced: true,
  state: {
    eventId: null,
    contactEntity: {
      isLoading: false,
      data: null,
      error: null,
    },
    promoPageEntity: {
      isLoading: false,
      data: null,
      error: null,
    },
    promoPageListAllEntity: {
      isLoading: false,
      data: null,
      error: null,
    },
    promoPageCodeListAllEntity: {
      isLoading: false,
      data: null,
      error: null,
    },
  },
  getters: {
    eventId: (state: TPromoPageStoreState): number => {
      return state.eventId;
    },
    contact: (state: TPromoPageStoreState): TContact => {
      return state.contactEntity.data;
    },
    promoPage: (state: TPromoPageStoreState): TPromoPage => {
      return state.promoPageEntity.data;
    },
    promoPageListAll: (state: TPromoPageStoreState): TPromoPage[] => {
      return state.promoPageListAllEntity.data;
    },
    promoPageCodeList: (state: TPromoPageStoreState): TCodeList[][] | void => {
      if(state.promoPageCodeListAllEntity.data) {
        return state.promoPageCodeListAllEntity.data.List;
      }
    },
    isLoading: (state: TPromoPageStoreState): boolean => {
      return state.contactEntity.isLoading || state.promoPageEntity.isLoading;
    },
    isPromoPageLoading: (state: TPromoPageStoreState): boolean => {
      return state.promoPageEntity.isLoading;
    },
    videoStreamEmbed: (state: TPromoPageStoreState): string => {
      return state.promoPageEntity.data && state.promoPageEntity.data.video_stream_embed;
    },
  },
  actions: {

    reset: ({ commit }: ActionContext<TPromoPageStoreState, TAppStoreState>): void => {
      commit('setEventId', null);
    },

    refresh: ({ commit, dispatch, state }: ActionContext<TPromoPageStoreState, TAppStoreState>): void => {
      commit('refresh');
      dispatch('getPromoPage', state.eventId);
    },

    createPromoPage: async (context: ActionContext<TPromoPageStoreState, TAppStoreState>, promoPageData): Promise<TPromoPage> => {
      const { commit } = context;

      commit('createPromoPageRequest');
      let data;
      try {
        data = await createPromoPageRequest.load(promoPageData);
        return data;
      } catch (error) {
        commit('createPromoPageError', error);
        return null;
      } finally {
        commit('createPromoPage', data);
      }
    },

    deletePromoPage: async (context: ActionContext<TPromoPageStoreState, TAppStoreState>, payload): Promise<void> => {
      const { commit } = context;

      try {
        return await deletePromoPageRequest.load(payload);
      } catch (error) {
        commit('deletePromoPageError', error);
        return null;
      }
    },

    deletePromoPageByCode: async (context: ActionContext<TPromoPageStoreState, TAppStoreState>, payload): Promise<void> => {
      const { commit } = context;

      try {
        return await deletePromoPageByCodeRequest.load(payload);
      } catch (error) {
        commit('deletePromoPageByCodeRequestError', error);
        return null;
      }
    },

    getPromoPageListAll: async (context: ActionContext<TPromoPageStoreState, TAppStoreState>, eventId): Promise<TPromoPage[]> => {
      const { commit } = context;
      commit('promoPageListAllRequest');
      let data;
      try {
        data = await promoPageListAllRequest.load(eventId);
        return data;
      } catch (error) {
        commit('promoPageListAllError', error);
        return null;
      } finally {
        commit('promoPageListAll', data);
      }
    },
    getPromoPageCodeList: async (context: ActionContext<TPromoPageStoreState, TAppStoreState>, eventId): Promise<TApiListResponse<TCodeList[]>> => {
      const { commit } = context;
      commit('promoPageListAllRequest');
      let data;
      try {
        data = await getPromoPageCodeListRequest.load(eventId);
        return data;
      } catch (error) {
        commit('getPromoPageCodeListError', error);
        return null;
      } finally {
        commit('getPromoPageCodeList', data);
      }
    },
    // TODO: get rid of this
    promoPageUpdated: ({ commit, dispatch, state }: ActionContext<TPromoPageStoreState, TAppStoreState>): void => {
      commit('promoPageUpdated');
      dispatch('getPromoPage', state.eventId);
    },

    getContact: async (context: ActionContext<TPromoPageStoreState, TAppStoreState>, eventId: number): Promise<TContact> => {
      const { commit, state } = context;

      commit('setEventId', eventId);

      if (state.contactEntity.data) {
        return state.contactEntity.data;
      }

      if (state.contactEntity.isLoading) {
        try {
          return await getEventContactRequest.promise;
        } catch (error) {
          return null;
        }
      }

      commit('contactRequest');
      let data;
      try {
        data = await getEventContactRequest.load({ eventId });
        return data;
      } catch (error) {
        commit('contactError', error);
        return null;
      } finally {
        commit('contact', data);
      }
    },
    getPromoPage: async (context: ActionContext<TPromoPageStoreState, TAppStoreState>, eventId: number): Promise<TPromoPage> => {
      const { commit, state, dispatch } = context;

      commit('setEventId', eventId);

      if (state.promoPageEntity.data) {
        return state.promoPageEntity.data;
      }

      if (state.promoPageEntity.isLoading) {
        try {
          return await getContactPromoPageRequest.promise;
        } catch (error) {
          return null;
        }
      }
      const contact = await dispatch('getContact', eventId);
      if (!contact) {
        commit('promoPageError', state.contactEntity.error || new Error('Could not load contact.'));
        return null;
      }

      commit('promoPageRequest');
      let data;
      try {
        data = await getContactPromoPageRequest.load({ eventId, contactId: contact.id });
        return data;
      } catch (error) {
        commit('promoPageError', error);
        return null;
      } finally {
        commit('promoPage', data);
      }
    },

  },
  mutations: {
    refresh(state: TPromoPageStoreState): void {
      state.contactEntity.data = null;
      state.contactEntity.isLoading = false;
      state.contactEntity.error = null;
      state.promoPageEntity.data = null;
      state.promoPageEntity.isLoading = false;
      state.promoPageEntity.error = null;
    },
    setEventId(state: TPromoPageStoreState, eventId: number): void {
      if (state.eventId === eventId) {
        return;
      }
      getEventContactRequest.cancel();
      getContactPromoPageRequest.cancel();

      state.eventId = eventId || null;
      state.contactEntity.data = null;
      state.contactEntity.isLoading = false;
      state.contactEntity.error = null;
      state.promoPageEntity.data = null;
      state.promoPageEntity.isLoading = false;
      state.promoPageEntity.error = null;
    },
    promoPageUpdated(state: TPromoPageStoreState): void {
      state.promoPageEntity.data = null;
      state.promoPageEntity.isLoading = false;
      state.promoPageEntity.error = null;
    },
    contactRequest(state: TPromoPageStoreState): void {
      state.contactEntity.data = null;
      state.contactEntity.isLoading = true;
      state.contactEntity.error = null;
    },
    contactError(state: TPromoPageStoreState, error: Error): void {
      state.contactEntity.error = error;
    },
    contact(state: TPromoPageStoreState, contact: TContact): void {
      if (contact) {
        contact.fullName = ContactHelper.getFullName(contact);
        contact.fullCompany = ContactHelper.getFullCompany(contact);
      }
      state.contactEntity.data = contact || null;
      state.contactEntity.isLoading = false;
    },
    promoPageRequest(state: TPromoPageStoreState): void {
      state.promoPageEntity.data = null;
      state.promoPageEntity.isLoading = true;
      state.promoPageEntity.error = null;
    },
    promoPageError(state: TPromoPageStoreState, error: Error): void {
      state.promoPageEntity.error = error;
    },
    promoPage(state: TPromoPageStoreState, promoPage: TPromoPage): void {
      state.promoPageEntity.data = promoPage || null;
      state.promoPageEntity.isLoading = false;
    },

    createPromoPageRequest(state: TPromoPageStoreState): void {
      state.promoPageEntity.data = null;
      state.promoPageEntity.isLoading = false;
      state.promoPageEntity.error = null;
    },
    createPromoPageError(state: TPromoPageStoreState, error: Error): void {
      state.promoPageEntity.error = error;
    },
    createPromoPage(state: TPromoPageStoreState, promoPage: TPromoPage): void {
      state.promoPageEntity.data = promoPage || null;
      state.promoPageEntity.isLoading = false;
    },
    deletePromoPageError(state: TPromoPageStoreState, error: Error): void {
      state.promoPageEntity.error = error;
    },

    deletePromoPageByCodeRequestError(state: TPromoPageStoreState, error: Error): void {
      state.promoPageEntity.error = error;
    },
    promoPageListAllRequest(state: TPromoPageStoreState): void {
      state.promoPageListAllEntity.data = null;
      state.promoPageListAllEntity.isLoading = false;
      state.promoPageListAllEntity.error = null;
    },
    promoPageListAllError(state: TPromoPageStoreState, error: Error): void {
      state.promoPageListAllEntity.error = error;
    },
    promoPageListAll(state: TPromoPageStoreState, response: TPromoPage[]): void {
      state.promoPageListAllEntity.data = response || null;
      state.promoPageListAllEntity.isLoading = false;
    },

    getPromoPageCodeListRequest(state: TPromoPageStoreState): void {
      state.promoPageCodeListAllEntity.data = null;
      state.promoPageCodeListAllEntity.isLoading = false;
      state.promoPageCodeListAllEntity.error = null;
    },
    getPromoPageCodeListError(state: TPromoPageStoreState, error: Error): void {
      state.promoPageCodeListAllEntity.error = error;
    },
    getPromoPageCodeList(state: TPromoPageStoreState, response: TApiListResponse<TCodeList[]>): void {
      state.promoPageCodeListAllEntity.data = response || null;
      state.promoPageCodeListAllEntity.isLoading = false;
    },
  },
};

export default promoPageStore;
