import BaseApi from '@/_types/api/base.api.class';
import { AxiosRequestConfig, CancelToken } from 'axios';
import { TBadgeNotifications } from '@/_modules/notifications-popup/types/badgeNotifications.type';

export type TGetUnreadCountParams = {
  eventId: number;
}

export class BadgeNotificationsApi extends BaseApi {

  public async getUnreadCount(eventId: number, cancelToken?: CancelToken): Promise<TBadgeNotifications> {
    const config: AxiosRequestConfig = {
      url: `/event/${eventId}/notification`,
      method: 'GET',
      cancelToken,
    };

    let response;

    try {
      response = await this.axios.request<TBadgeNotifications>(config);
    } catch (error) {
      throw this.responseError(error);
    }
    return response.data;
  }

}

const badgeNotificationsApi = new BadgeNotificationsApi();
export default badgeNotificationsApi;
